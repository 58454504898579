import {ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {ServicesModule} from '@mptl/web/services';
import {EffectsModule} from '@ngrx/effects';
import {RootStoreModule} from '@mptl/web/root-store';
import {environment} from '../environments/environment';
import {AppErrorHandler} from './app-error-handler';
import {MAT_SELECT_SCROLL_STRATEGY_PROVIDER} from '@angular/material/select';
import {ServiceWorkerModule} from '@angular/service-worker';
import {NgcCookieConsentConfig, NgcCookieConsentModule} from "ngx-cookieconsent";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppInstallNowModule} from "@mptl/web/ui/app-install-now";
import {UiToastModule} from "@mptl/web/ui/toast";


const cookieConfig: NgcCookieConsentConfig =
  {
    "cookie": {
      "domain": window.location.protocol + '//' + window.location.host
    },
    enabled: false,
    "position": "bottom",
    "theme": "classic",
    "palette": {
      "popup": {
        "background": "#742b96",
        "text": "#ffffff"
      },
      "button": {
        "background": "#f1d600",
        "text": "#000000"
      }
    },
    "type": "opt-out",
    "content": {
      "message": "This website uses cookie for better user experience. Approve this by clicking Accept button",
      "allow": "Accept",
      "link": "Learn more",
      "href": window.location.protocol + '//' + window.location.host + '/PrivacyPolicy',
      "policy": "Cookie Policy"
    }
  };


@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    UiToastModule.forRoot(),
    ServicesModule.forRoot(),
    EffectsModule.forRoot([]),
    NgcCookieConsentModule.forRoot(cookieConfig),
    AppInstallNowModule,
    RootStoreModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register(`custom-sw-worker.js?${environment.version}`, {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: 'BASE_URL',
      useValue: environment.apiUrl,
    },
    {
      provide: 'APP_URL',
      useValue: environment.appUrl,
    },
    {
      provide: 'ENVIRONMENT_NAME',
      useValue: environment.environmentName,
    },
    {
      provide: 'APP_INSIGHT_INSTRUMENT_KEY',
      useValue: environment.appInsights.instrumentationKey,
    },
    {
      provide: 'APP_VERSION',
      useValue: environment.version,
    },
    {
      provide: ErrorHandler,
      useClass: AppErrorHandler,
    },
    MAT_SELECT_SCROLL_STRATEGY_PROVIDER,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
