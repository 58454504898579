import {createUrl} from "./base-url";

export const TagUrls = {
  createTag: (baseUrl: string) =>
    createUrl(baseUrl, 'Tag/createTag'),
  updateTag: (baseUrl: string) =>
    createUrl(baseUrl, 'Tag/updateTag'),
  deleteTag: (baseUrl: string) =>
    createUrl(baseUrl, 'Tag/deleteTag/:id'),
  getAllTags: (baseUrl: string) =>
    createUrl(baseUrl, 'Tag/getAllTags'),
  getAllTagsDropDown: (baseUrl: string) =>
    createUrl(baseUrl, 'Tag/getAllTagsDropDown'),
  getTagById: (baseUrl: string) =>
    createUrl(baseUrl, 'Tag/getTagById/:id'),
}
