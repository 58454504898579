<div class="text-center install-apk-body">
  <ng-container *ngIf="!data.isUpdateMode; else updateApp">
    <h1>Install My Path To Learning To your device</h1>

    <img src="/assets/icons/icon-144x144.png" alt="icon" /><br />

    <p class="mt-3">
      Install this web as an application on your home screen for quick <br />and
      easy access when you’re on the go.<br />
    </p>
    <p>
      <ng-container *ngIf="plateForm.ANDROID; else instruction">
        just click <strong>Install</strong> button bellow.
      </ng-container>
      <ng-template #instruction
        >Just follow instruction given bellow.</ng-template
      >
    </p>
    <p class="mt-3" *ngIf="(plateForm.IOS || breakPointObserver.isMatched([isIpad, isIpadPro]) ) && plateForm.SAFARI">
      tap
      <!--    <button mat-icon-button role='button' type='button' color='primary'>-->
      <mat-icon>ios_share</mat-icon>
      <!--    </button>-->
      then “Add to Home Screen”
    </p>

    <p class="mt-3" *ngIf="plateForm.IOS && !plateForm.SAFARI">
      Open this on safari, then Just tap
      <!--    <button mat-icon-button role='button' type='button' color='primary'>-->
      <mat-icon>ios_share</mat-icon>
      <!--    </button>-->
      then “Add to Home Screen”.
    </p>
  </ng-container>
  <ng-template #updateApp>
    <h1>Update Available!</h1>
    <p>click "Update" to activate new changes.</p>
  </ng-template>
  <button
    *ngIf="(plateForm.ANDROID || data.isUpdateMode || plateForm.isBrowser) && !(plateForm.SAFARI)"
    class="mt-3"
    role="button"
    type="button"
    (click)="close(true)"
    mat-stroked-button
    color="primary"
  >
    <mat-icon class="mr-2">file_download</mat-icon>
    {{ data.isUpdateMode ? 'Update' : 'Install' }}
  </button>
  <div class="bottom-sheet-close-btn">
    <button
      mat-icon-button
      class="center-model-close"
      color="warn"
      role="button"
      type="button"
      (click)="close()"
    >
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
