import {Component, OnDestroy} from '@angular/core';

// libs
import {Subject} from 'rxjs';
import {
  AbstractControl,
  FormArray,
  FormGroup,
  ValidationErrors, ValidatorFn,
} from '@angular/forms';
import PasswordValidator from "password-validator";

export class CustomValidators {
  static emailRegex: RegExp =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static whitespace(control: AbstractControl): ValidationErrors | null {
    if (control?.value) {
      const isWhitespace = (control?.value || '')?.trim()?.length === 0;
      const isValid = !isWhitespace;
      if (!isValid) {
        return {['whitespace']: true};
      }
    }
    return null;
  }

  static email(control: AbstractControl): ValidationErrors | null {
    if (control?.value) {
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const isValid = emailRegex.test(control.value);
      if (!isValid) {
        return {email: true};
      }
    }
    return null;
  }

  static passWordValidator(passWordScheme: PasswordSchema = new PasswordSchema()): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value) {
        const pwd = control.value;
        if (typeof pwd === 'string') {
          let pwdSchema = new PasswordValidator();
          if (passWordScheme.min) {
            pwdSchema.min(passWordScheme.min, `Password must have minimum ${passWordScheme.min} characters.`);
          }
          if (passWordScheme.max) {
            pwdSchema.max(passWordScheme.max, `Password can have maximum ${passWordScheme.max} character.`);
          }
          if (passWordScheme.lowerCase) {
            pwdSchema.lowercase(1, `Password must have minimum 1 lowercase character.`);
          }
          if (passWordScheme.upperCase) {
            pwdSchema.uppercase(1, `Password must have minimum 1 upperCase character.`);
          }
          if (passWordScheme.symbols) {
            pwdSchema.symbols(1, `Password must have minimum 1 special character like !@#$%^&*(){}[]|\\:;"'~<>?,./`);
          }
          if (passWordScheme.digits) {
            pwdSchema.digits(1, `Password must have minimum 1 digit.`);
          }
          if (passWordScheme.space) {
            pwdSchema.spaces(1, `Password must have minimum 1 space.`)
          } else {
            pwdSchema.not().spaces(undefined, 'Password must not have any space in between.');
          }
          if (passWordScheme.regx) {
            pwdSchema.has(passWordScheme.regx, `Password is invalid.`)
          }
          if (passWordScheme.whiteListedPasswords?.length) {
            pwdSchema.oneOf(passWordScheme.whiteListedPasswords)
          }
          if (passWordScheme.blackListedPasswords?.length) {
            pwdSchema.not().oneOf(passWordScheme.blackListedPasswords)
          }
          const result = pwdSchema.validate(pwd, {details: true});
          if (typeof result === 'boolean') {
            return result ? null : {invalid: true}
          } else {
            const errors: ValidationErrors = {};
            result.forEach(error => {
              errors[error.validation ?? 'error'] = error.message ?? 'Password is invalid'
            });
            if (result.length) {
              // return {[result[0].validation ?? 'error'] : result[0].message ?? 'Password is invalid'};
              return {'invalid': {...result[0]}};
            } else {
              return null
            }
          }
        } else {
          return {invalid: true}
        }
      }
      return null;
    }
  }
}

@Component({
  template: '',
})
export abstract class BaseComponent implements OnDestroy {
  private _destroy$: Subject<any> | undefined;

  get destroy$() {
    if (!this._destroy$) {
      this._destroy$ = new Subject();
    }
    return this._destroy$;
  }

  ngOnDestroy() {
    if (this._destroy$) {
      this._destroy$.next(true);
      this._destroy$.complete();
    }
  }

  makeFormDirty(form: FormGroup | FormArray): void {
    if (form instanceof FormGroup) {
      // tslint:disable-next-line:forin
      for (const controlsKey in form.controls) {
        const control = form.get(controlsKey);
        if (control instanceof FormArray || control instanceof FormGroup) {
          this.makeFormDirty(control);
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        control.markAsTouched();
        control?.markAsDirty();
      }
    } else {
      form.controls.forEach((c) => {
        if (c instanceof FormArray || c instanceof FormGroup) {
          this.makeFormDirty(c);
        }
        c.markAsTouched();
        c.markAsDirty();
      });
    }
  }

  public keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    const inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  decimalFilter(event: any) {
    const reg = /^-?\d*(\.\d{0,2})?$/;
    let input = event.target.value + String.fromCharCode(event.charCode);

    if (!reg.test(input)) {
      event.preventDefault();
    }
  }

  // public keyPressAlphaNumeric(event) {
  //   const inp = String.fromCharCode(event.keyCode);
  //   // Allow numbers, alpahbets, space, underscore
  //   if (/[a-zA-Z0-9-_ ]/.test(inp)) {
  //     return true;
  //   } else {
  //     event.preventDefault();
  //     return false;
  //   }
  // }
}


export class PasswordSchema {
  min?: number = 8;
  max?: number = 100;
  lowerCase?: boolean | number = true;
  upperCase?: boolean | number = true;
  digits?: boolean | number = true;
  symbols?: boolean = true;
  space?: boolean = false;
  whiteListedPasswords?: string[] = [];
  blackListedPasswords?: string[] = [];
  regx?: RegExp;
}
