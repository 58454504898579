import { Observable } from 'rxjs';
import { AppInsightsLoggingService } from '@mptl/web/services';
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { tapResponse } from '@ngrx/component-store';

export function tapResponseWithLog<T, E = unknown>(
  nextFn: (next: T) => void,
  errorFn: (error: E) => void,
  appInsightService: AppInsightsLoggingService,
  completeFn?: () => void
): (source$: Observable<T>) => Observable<T> {
  return (source$) =>
    source$.pipe(
      tapResponse(
        nextFn,
        (error: any) => {
          if (appInsightService) {
            appInsightService.logException(error, SeverityLevel.Critical);
          }
          errorFn(error);
        },
        completeFn
      )
    );
}
