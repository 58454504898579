import {Inject, Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {
  BaseEnumNames,
  BaseResponse,
  IBaseOption,
  IUnsavedAttemptDetail,
  SlackException,
  UserProfile,
} from '@mptl/models';
import {HttpWrapperService} from './httpWrapper';
import {GeneralUrls} from './apiUrls/general-urls';
import {distinctUntilChanged, map} from 'rxjs/operators';
import {NoInternetConnectionComponent} from '@mptl/web/ui/no-internet';
import {MatDialogRef} from '@angular/material/dialog';
import {NgDialogAnimationService} from './dialog.service';

interface LayoutLoaderState {
  show: boolean;
  key: string | null;
}

declare var window: any;
declare var document: any;
declare var navigator: any;

@Injectable()
export class GeneralService {
  noInterNetDialog: MatDialogRef<NoInternetConnectionComponent>;
  SidebarStatus: BehaviorSubject<'SHOW' | 'HIDE'> = new BehaviorSubject<'SHOW' | 'HIDE'>('SHOW');
  sidebarStatus$: Observable<'SHOW' | 'HIDE'> =
    this.SidebarStatus.asObservable();

  StudentHeaderStatus: BehaviorSubject<'SHOW' | 'HIDE'> = new BehaviorSubject<'SHOW' | 'HIDE'>('SHOW');
  StudentHeaderStatus$: Observable<'SHOW' | 'HIDE'> =
    this.SidebarStatus.asObservable();
  promptEvent: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  promptEvent$: Observable<any> = this.promptEvent.asObservable();
  appInstalled: BehaviorSubject<boolean> = new BehaviorSubject<any>(false);
  appInstalled$: Observable<boolean> = this.appInstalled.asObservable();
  public version: BehaviorSubject<string> = new BehaviorSubject<string>(this.APP_VERSION);
  public version$: Observable<string> = this.version.asObservable().pipe(distinctUntilChanged());
  public refreshButtonPress: Subject<undefined> = new Subject<undefined>();
  public refreshButtonPress$: Observable<undefined> = this.refreshButtonPress.asObservable();
  public isFullScreenMode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isFullScreenMode$: Observable<boolean> = this.isFullScreenMode.asObservable();
  public browserDetails: {
    browserName: string;
    browserVersion: number;
    operatingSystem: string;
  } = {browserName: 'unknown', operatingSystem: 'unknown', browserVersion: 0}

  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any,
    @Inject('APP_VERSION') public APP_VERSION: any,
    @Inject('ENVIRONMENT_NAME')
    private environmentName: 'local' | 'development' | 'production',
    public dialog: NgDialogAnimationService,
  ) {
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
    // @ts-ignore
    if (!navigator.sayswho) {
      // @ts-ignore
      navigator.sayswho = (function () {
        var ua = navigator.userAgent;
        var tem;
        var M = ua.match(/(opera|chrome|safari|firefox|silk|edge|edg|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])) {
          tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
          return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
          tem = ua.match(/\b(OPR|Edge|Edg)\/(\d+)/);
          if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) M.splice(1, 1, tem[1]);
        return M.join(' ');
      })();
    }

    let userAgent = window.navigator.userAgent,
      platform = window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    // @ts-ignore
    const versionInfo = navigator.sayswho;
    const browserInfo = versionInfo.split(' ')
    this.browserDetails.browserName = browserInfo[0]
    this.browserDetails.browserVersion = +browserInfo[1]
    this.browserDetails.operatingSystem = os;
  }

  get isBrowserSupported(): boolean {
    const supportedBrowserNames = ['CHROME', 'SAFARI'];
    const deviceType = this.isMobile ? 'MOBILE' : 'DESKTOP';
    const supportedMinimumVersions = {
      'CHROME-MOBILE': 119,
      'CHROME-DESKTOP': 49,
      'SAFARI-MOBILE': 14.5,
      'SAFARI-DESKTOP': 14.1,
    }
    return (supportedBrowserNames.includes(this.browserDetails.browserName?.toUpperCase()) && this.browserDetails.browserVersion >= supportedMinimumVersions[this.browserDetails.browserName?.toUpperCase() + '-' + deviceType]);
  }

  public userPageLayoutLoader = new BehaviorSubject<LayoutLoaderState>({
    key: null,
    show: false,
  });

  get user(): UserProfile {
    return <UserProfile>this._user;
  }

  set user(userData: UserProfile | undefined) {
    this._user = userData;
  }

  set studentHeaderStatus(status: 'SHOW' | 'HIDE') {
    this.StudentHeaderStatus.next(status);
  }

  get studentHeaderStatus(): 'SHOW' | 'HIDE' {
    return this.StudentHeaderStatus.getValue();
  }

  set sidebarStatus(status: 'SHOW' | 'HIDE') {
    this.SidebarStatus.next(status);
  }

  get sidebarStatus(): 'SHOW' | 'HIDE' {
    return this.SidebarStatus.getValue();
  }

  get isMobile() {
    // credit to Timothy Huang for this regex test:
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|CrOS/i.test(
      navigator.userAgent
    );
    const isMAc = /Mac/i.test(navigator.userAgent);
    if (isMAc) {
      try {
        document.createEvent("TouchEvent");
        isMobile = true;
      } catch (e) {
      }
    }
    return isMobile;
  }

  get isIos() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  }

  get isInstalled(): boolean {
    const value = document.cookie.match(/my_path_to_learning=installed/);
    return !!value;
  }

  get isStandalone(): boolean {
    return (
      (window.navigator as any)?.standalone ||
      window.matchMedia('(display-mode: standalone)').matches
    );
  }

  private _user: UserProfile | undefined;

  getEnum(
    enumName: BaseEnumNames
  ): Observable<BaseResponse<IBaseOption[], BaseEnumNames>> {
    return this.http
      .get(GeneralUrls.getEnum(this.baseUrl).replace(':enumName', enumName))
      .pipe(
        map((response: BaseResponse<IBaseOption[], BaseEnumNames>) => {
          response.request = enumName;
          response.data = response.data.map((s) => ({...s, hidden: false}));
          return response;
        })
      );
  }


  postSlackMessage(
    slackException: SlackException
  ): Observable<BaseResponse<string, SlackException>> {
    if (this.environmentName !== 'local') {
      return this.http
        .post(GeneralUrls.sendSlackMessage(this.baseUrl), {message: slackException?.toString()})
        .pipe(
          map((response: BaseResponse<string, SlackException>) => {
            response.request = slackException;
            return response;
          })
        );
    } else {
      const data: BaseResponse<string, SlackException> = new BaseResponse<string, SlackException>();
      data.request = slackException;
      data.status = 'success';
      data.errors = [];
      data.data = '';
      data.hasError = false;
      data.queryString = '';
      return of(data)
    }
  }


  getApiVersion() {
  }

  installApp() {
    this.promptEvent.getValue()?.prompt(); // Wait for the user to respond to the prompt
    this.promptEvent.getValue()?.userChoice.then((choice: any) => {
      if (choice.outcome === 'accepted') {
        console.log('User accepted');
      } else {
        console.log('User dismissed');
      }
    });
  }

  updateOnlineStatus = (event: any) => {
    if (window.navigator?.onLine) {
      if (this.noInterNetDialog) {
        this.noInterNetDialog.close();
      }
    } else {
      this.noInterNetDialog = this.dialog.open(NoInternetConnectionComponent, {
        data: null,
        hasBackdrop: true,
        autoFocus: false,
        disableClose: true,
        panelClass: 'center-model',
      });
    }
  };

  private setCookie() {
    document.cookie =
      'my_path_to_learning=installed' +
      '; max-age=' +
      60 * 60 * 24 * 2 +
      '; SameSite=None; Secure';
  }

  get isTouchEnabled() {
    return ('ontouchstart' in window) ||
      ((navigator as any).maxTouchPoints > 0) ||
      ((navigator as any).msMaxTouchPoints > 0);
  }

  getAttemptsByIds(attemptIds: number[]) {
    return this.http
      .post(GeneralUrls.getAttemptsByIds(this.baseUrl), {attemptIds}).pipe(
        map((response: BaseResponse<IUnsavedAttemptDetail[], { attemptIds: number[] }>) => {
          response.request = {attemptIds};
          return response;
        })
      )
  }
}
