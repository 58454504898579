export class ReleaseNote {
  id: number;
  notes: string;
  version: string;
  createDate: Date;
  videoKey: string;
  videoURL : string;
  previewData : string;
}

export class ReleaseNoteUserReq{
  id? : number;
  userId? : number;
  studentId? : number;
}
