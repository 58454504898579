export class ISettingsUpdateRequest {
  model: GlobleSettings[];
}

export class GlobleSettings {
  id: number;
  key: GlobalSettingEnum;
  value: string;
}



export enum GlobalSettingEnum {
  ReminderTriggerTime = "ReminderTriggerTime"
}
