import {BaseFilterRequest} from '../base/baseResponse';
import {DirectoryType} from '../directory';

export class AssignmentFilterRequest extends BaseFilterRequest {
  assignmentGroupId: number;
}


export class AssignmentList {
  id: number;
  assignmentName: string;
  assignmentType: string;
  totalWords: number;
  status: string;
}

export class CreateAssignment {
  id: number;
  assignmentName: string;
  assignmentType: AssignmentTypes;
  subType: AssignmentSubTypes;
  assignmentWords: AssignmentWords[];
  assignmentGroupId: number;
  assignmentConfig: IAssignmentConfig;
  isAttemptAvailable: boolean;
  isViewOnly?: boolean;
  operatorsConfig: IAssignmentConfig[];
  introductionSlides: IntroSlide[];
  feedbackQuestions: FeedBackQuestion[];

}


export interface IAssignmentConfig {
  id: number;
  operandStartLimit: number;
  operandEndLimit: number;
  operators: string,
  totalTimeInMinute: number;
  isAllowNegative: boolean;
  isAllowDecimal: boolean;
  value: boolean;
}


export class AssignmentWords {
  id: number;
  word: string;
  answer: string;
  sequence: number;
  audioRecordingURL: string;
  audioKey: string;
  imageKey: string;
  imageUrl: string;
  deleteFile?: boolean;
  sounds?: { [key: string]: string };
  type?: AssignmentTypes;
  equation?: {
    digit1: number;
    digit2: number;
    operator: string;
  };
  editMode?: boolean;
  showImageAsBackground?: boolean;
}

export class SaveAssignment {
  words: AssignmentWords[];
  assignmentId: number;
}

export class AddAssignmentAttachment {
  assignmentId: number;
  introductionSlides: AttachmentSequenceVM[] = [];
  feedbackQuestions: AttachmentSequenceVM[] = [];
}

export class AttachmentSequenceVM {
  relationalId: number;
  sequence: number;
}

export class AssignmentDashboardSummaryQuery {
  totalAssignment: number;
  totalWordAssignment: number;
  totalMathAssignment: number;
}

export enum AssignmentTypes {
  MATH = 'MATH',
  SPELLING = 'SPELLING',
}


export enum AssignmentSubTypes {
  STANDARD = 'STANDARD',
  RAPID = 'RAPID',
}

export enum RecordingTypesEnum {
  SAY_WORD = 'SAY_WORD',
  SPELL_IT = 'SPELL_IT',
  SAY_WORD_THEN_SPELL_IT = 'SAY_WORD_THEN_SPELL_IT',
  SPELL_IT_THEN_SAY_WORD = 'SPELL_IT_THEN_SAY_WORD',
  SAY_EQUATION = 'SAY_EQUATION',
  SAY_EQUATION_WITH_ANSWER = 'SAY_EQUATION_WITH_ANSWER',
}

export enum AudioTypesEnum {
  NONE = 'NONE',
  TEACHER_RECORDING = 'TEACHER_RECORDING',
  AMAZONE_POLLY = 'AMAZONE_POLLY',
}

export interface IAssignmentWordsResponse {
  words: ITestDayAssignmentWord[];
  testId: number;
  hasFeedbackQuestion: boolean;
}

export interface ITestDayAssignmentWord {
  id: number;
  word: string;
  answer: string;
  reviewCount: number;
  isReviewed: boolean;
  isAttemptAvailable: boolean;
  imageUrl: string;
}


export interface IWordCreate {
  assignmentId: number;
  word: string;
  answer: string;
  sequence: number;
  audioKey: string;
  imageKey: string;
  imageUrl: string;
  audioRecordingURL: string;
}

export interface IWordUpdate {
  id: number;
  word: string;
  answer: string;
  sequence: number;
  audioKey: string;
  imageKey: string;
  imageUrl: string;
  audioRecordingURL: string;
}

export interface IWordSequenceChange {
  word: { assignmentWordId: number; sequence: number; }[];
}

export interface IAttachmentSequenceChange {
  introductionSlides: { attachmentId: number; sequence: number; }[];
  feedBackQuestions: { attachmentId: number; sequence: number; }[];
}

export class MoveAssignmentFromDirectory {
  id: number;
  moveToDirectoryId: number;
  moveFromDirectoryId: number;
  directoryType: DirectoryType;
}


export interface ICopyAndArchiveRequest {
  assignmentId: number;
  operationType: 'COPYANDARCHIVE' | 'COPYONLY' | 'ARCHIVEONLY';
  name?: string;
}

export class GenerateMathEquation {
  operandStartLimit: number;
  operandEndLimit: number;
  operators: string[];
  maxEquationLimit: string;
}

export class IntroSlide {
  id: number;
  title: string;
  audioType: string;
  videoKey: string;
  videoURL: string;
  content: string;
  audioKey: string;
  audioURL: string;
  storagePath: string;
  slideType: string;
  // useAmazonePolly: string;
  previewURL: string;
  previewData: string;
  sequenceNo: number;
  attachmentId?: number;
}

export enum SlideType {
  Text = 'Text',
  Video = 'Video',
  Audio = 'Audio'
}

export enum AudioType {
  None = 'None',
  Record = 'Record',
  Upload = 'Upload',
  AmazonPolly = 'Amazon Polly'
}

export enum AnswerType {
  Image = 'Image',
  Text = 'Text',
  Emoji = 'Emoji'
}

export class FeedBackQuestion {
  id: number;
  title: string;
  teacherId: number;
  optionType: string;
  audioType: string;
  audioKey: string;
  audioURL: string;
  dataSources: DataSources[];
  sequence: number;
  attachmentId?: number;
}

export class DataSources {
  id: number;
  sequence: number;
  value: number;
  imageUrl: string;
  text: string;
}

export class RemoveAttachment {
  assignmentId: number;
  slideId?: number;
  questionId?: number;
}

export class AssignmentTypeVM {
  key: 'math' | 'spelling';
  displayName: string;
}

export class ImportAssignmentWords {
  fileKey: string;
  assignmentId: number;
}


export const AssignmentTypeArr: AssignmentTypeVM[] = [{
  key: 'spelling',
  displayName: 'English Language Arts'
},
  {key: 'math', displayName: 'Mathematics'}];


export const MathLabel = 'Mathematics';
export const SpellingLabel = 'English Language Arts';
export const SpellingSortLabel = 'ELA';
