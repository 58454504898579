import { createUrl } from './base-url';

export const GroupUrls = {
  createGroup: (baseUrl: string) => createUrl(baseUrl, 'Group/createGroup'),
  updateGroup: (baseUrl: string) => createUrl(baseUrl, 'Group/updateGroup'),
  getGroupById: (baseUrl: string) => createUrl(baseUrl, 'Group/getGroupById/:GroupId '),
  removeGroup: (baseUrl: string) => createUrl(baseUrl, 'Group/removeGroup '),
  getGroupList: (baseUrl: string) => createUrl(baseUrl, 'Group/getGroupList'),
  getGroupListForDropdown: (baseUrl: string) => createUrl(baseUrl, 'Group/getGroupListForDropdown?ExcludeDemo=NO'),
  createDefaultGroup: (baseUrl: string) =>
    createUrl(baseUrl, 'Group/createDefaultGroup'),
};
