import { createUrl } from './base-url';

export const FileUrls = {
  uploadImageOnLocal: (baseUrl: string) =>
    createUrl(baseUrl, 'File/uploadImageOnLocal'),
  uploadImageOnLocalFromUrl: (baseUrl: string) =>
    createUrl(baseUrl, 'File/uploadImageOnLocalFromUrl'),
  uploadFileOnLocal: (baseUrl: string) =>
    createUrl(baseUrl, 'File/uploadFileOnLocal'),
};
