import {createUrl} from './base-url';

export const AssignmentUrls = {
  createAssignment: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/createAssignment'),
  updateAssignment: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/updateAssignment'),
  saveAssignmentWords: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/saveAssignmentWords'),
  generateMathEquation: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/generateMathEquation'),
  getAssignmentById: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/getAssignmentById/:assignmentId'),
  removeAssignment: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/removeAssignment/:assignmentId'),
  getAssignmentList: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/getAssignmentList'),
  getAssignmentDropdownList: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/getAssignmentDropdownList'),
  getAssignmentDashbordSummaryQuery: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/getAssignmentDashbordSummaryQuery'),
  getAssignmentWordByTestDayId: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/getAssignmentWordByTestDayId/:testDayId'),
  getSoundUrls: (baseUrl: string) => createUrl(baseUrl, 'Assignment/getSoundUrls'),
  saveSingleAssignmentWord: (baseUrl: string) => createUrl(baseUrl, 'Assignment/saveSingleAssignmentWord'),
  updateSingleAssignmentWord: (baseUrl: string) => createUrl(baseUrl, 'Assignment/updateSingleAssignmentWord'),
  deleteSingleAssignmentWord: (baseUrl: string) => createUrl(baseUrl, 'Assignment/deleteSingleAssignmentWord?AssignmentWordId=:assignmentWordId'),
  sortAssignmentWord: (baseUrl: string) => createUrl(baseUrl, 'Assignment/sortAssignmentWord'),
  moveAssignmentFromDirectory: (baseUrl: string) => createUrl(baseUrl, 'Assignment/moveAssignmentFromDirectory'),
  copyAndArchiveAssignment: (baseUrl: string) => createUrl(baseUrl, 'Assignment/copyAndArchiveTest'),
  getAllIntroSlideByAssignmentId: (baseUrl: string) => createUrl(baseUrl, 'Assignment/getAllIntroSlideByAssignmentId'),
  addIntroSlide: (baseUrl: string) => createUrl(baseUrl, 'Assignment/addIntroSlide'),
  updateIntroSlide: (baseUrl: string) => createUrl(baseUrl, 'Assignment/updateIntroSlide'),
  getIntroSlideById: (baseUrl: string) => createUrl(baseUrl, 'Assignment/getIntroSlideById/:slideId'),
  deleteIntroSlide: (baseUrl: string) => createUrl(baseUrl, 'Assignment/deleteIntroSlide/:slideId'),
  getAllFeedbackQuestionsByAssignmentId: (baseUrl: string) => createUrl(baseUrl, 'Assignment/getAllFeedbackQuestionsByAssignmentId'),
  removeFeedbackQuestion: (baseUrl: string) => createUrl(baseUrl, 'Assignment/removeFeedbackQuestion/:FeedbackQuestionId'),
  addFeedbackQuestion: (baseUrl: string) => createUrl(baseUrl, 'Assignment/addFeedbackQuestion'),
  updateFeedbackQuestion: (baseUrl: string) => createUrl(baseUrl, 'Assignment/updateFeedbackQuestion'),
  getFeedbackQuestionById: (baseUrl: string) => createUrl(baseUrl, 'Assignment/getFeedbackQuestionById/:Id'),
  addAssignmentAttachment: (baseUrl: string) => createUrl(baseUrl, 'Assignment/addAssignmentAttachment'),
  removeAssignmentAttachment: (baseUrl: string) => createUrl(baseUrl, 'Assignment/removeAssignmentAttachment'),
  sortAssignmentAttachment: (baseUrl: string) => createUrl(baseUrl, 'Assignment/sortAssignmentAttachment'),
  importAssignmentWords: (baseUrl: string) => createUrl(baseUrl, 'Assignment/importAssignmentWords'),
  exportAssignmentWords: (baseUrl: string) => createUrl(baseUrl, 'Assignment/exportAssignmentWords/:AssignmentId'),
};
