import {Inject, Injectable} from '@angular/core';
import {HttpWrapperService} from './httpWrapper';
import {
  AddAssignmentAttachment,
  AssignmentDashboardSummaryQuery,
  AssignmentFilterRequest,
  AssignmentList,
  AssignmentWords,
  BaseResponse,
  CreateAssignment,
  FeedBackQuestion,
  GenerateMathEquation,
  IAssignmentListForDropDown,
  IAssignmentWordsResponse,
  IAttachmentSequenceChange,
  ICopyAndArchiveRequest,
  ImportAssignmentWords,
  IntroSlide,
  IWordCreate,
  IWordSequenceChange,
  IWordUpdate,
  MoveAssignmentFromDirectory,
  PaginatedResponse,
  RemoveAttachment,
  SaveAssignment
} from '@mptl/models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {AssignmentUrls} from './apiUrls/assignment-urls';
import {HttpClient} from "@angular/common/http";

@Injectable()
export class AssignmentService {
  constructor(
    private http: HttpWrapperService,
    private httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: any
  ) {
  }

  public createAssignment(
    request: CreateAssignment
  ): Observable<BaseResponse<number, CreateAssignment>> {
    return this.http
      .post(AssignmentUrls.createAssignment(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<number, CreateAssignment> = res;
          return data;
        })
      );
  }

  public updateAssignment(
    request: CreateAssignment
  ): Observable<BaseResponse<string, CreateAssignment>> {
    return this.http
      .post(AssignmentUrls.updateAssignment(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, CreateAssignment> = res;
          return data;
        })
      );
  }

  public getAssignmentById(
    id: string
  ): Observable<BaseResponse<CreateAssignment, string>> {
    return this.http
      .get(
        AssignmentUrls.getAssignmentById(this.baseUrl).replace(
          ':assignmentId',
          id
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<CreateAssignment, string> = res;
          return data;
        })
      );
  }

  public removeAssignment(
    id: string
  ): Observable<BaseResponse<string, string>> {
    return this.http
      .delete(
        AssignmentUrls.removeAssignment(this.baseUrl).replace(
          ':assignmentId',
          id
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<string, string> = res;
          return data;
        })
      );
  }

  public getAssignmentList(
    request: AssignmentFilterRequest
  ): Observable<BaseResponse<PaginatedResponse<AssignmentList[]>, AssignmentFilterRequest>> {
    return this.http
      .post(AssignmentUrls.getAssignmentList(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<PaginatedResponse<AssignmentList[]>,
            AssignmentFilterRequest> = res;
          data.request = request;
          return data;
        })
      );
  }

  public saveAssignmentWords(
    request: SaveAssignment
  ): Observable<BaseResponse<string, SaveAssignment>> {
    return this.http
      .post(AssignmentUrls.saveAssignmentWords(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, SaveAssignment> = res;
          return data;
        })
      );
  }

  public generateMathEquation(request: GenerateMathEquation): Observable<BaseResponse<AssignmentWords[], GenerateMathEquation>> {
    return this.http
      .post(AssignmentUrls.generateMathEquation(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<AssignmentWords[], GenerateMathEquation> = res;
          return data;
        })
      );
  }

  public getAssignmentDashbordSummaryQuery(): Observable<BaseResponse<AssignmentDashboardSummaryQuery, string>> {
    return this.http
      .get(AssignmentUrls.getAssignmentDashbordSummaryQuery(this.baseUrl))
      .pipe(
        map((res) => {
          const data: BaseResponse<AssignmentDashboardSummaryQuery, string> =
            res;
          return data;
        })
      );
  }

  public getAssignmentDropdownList(): Observable<BaseResponse<IAssignmentListForDropDown[], string>> {
    return this.http
      .get(AssignmentUrls.getAssignmentDropdownList(this.baseUrl))
      .pipe(
        map((res) => {
          const data: BaseResponse<IAssignmentListForDropDown[], string> = res;
          return data;
        })
      );
  }

  public getAssignmentWordByTestDayId(
    testDayId: number
  ): Observable<BaseResponse<IAssignmentWordsResponse, number>> {
    return this.http
      .get(
        AssignmentUrls.getAssignmentWordByTestDayId(this.baseUrl).replace(
          ':testDayId',
          testDayId?.toString()
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<IAssignmentWordsResponse, number> = res;
          data.request = testDayId;
          return data;
        })
      );
  }

  public getSoundUrls(): Observable<BaseResponse<string[], undefined>> {
    return this.http.get(AssignmentUrls.getSoundUrls(this.baseUrl)).pipe(
      map((res) => {
        const data: BaseResponse<string[], undefined> = res;
        return data;
      })
    );
  }

  public moveAssignmentFromDirectoryService(word: MoveAssignmentFromDirectory): Observable<BaseResponse<string, MoveAssignmentFromDirectory>> {
    return this.http.post(AssignmentUrls.moveAssignmentFromDirectory(this.baseUrl), word).pipe(
      map((res) => {
        const data: BaseResponse<string, MoveAssignmentFromDirectory> = res;
        data.request = word;
        return data;
      })
    );
  }

  public saveSingleAssignmentWordService(word: IWordCreate): Observable<BaseResponse<AssignmentWords, IWordCreate>> {
    return this.http.post(AssignmentUrls.saveSingleAssignmentWord(this.baseUrl), word).pipe(
      map((res) => {
        const data: BaseResponse<AssignmentWords, IWordCreate> = res;
        data.request = word;
        return data;
      })
    );
  }

  public updateSingleAssignmentWordService(word: IWordUpdate): Observable<BaseResponse<AssignmentWords, IWordUpdate>> {
    return this.http.post(AssignmentUrls.updateSingleAssignmentWord(this.baseUrl), word).pipe(
      map((res) => {
        const data: BaseResponse<AssignmentWords, IWordUpdate> = res;
        data.request = word;
        return data;
      })
    );
  }

  public deleteSingleAssignmentWordService(assignmentWordId: number): Observable<BaseResponse<string, number>> {
    return this.http.get(AssignmentUrls.deleteSingleAssignmentWord(this.baseUrl).replace(':assignmentWordId', assignmentWordId?.toString())).pipe(
      map((res) => {
        const data: BaseResponse<string, number> = res;
        data.request = assignmentWordId;
        return data;
      })
    );
  }

  public sortAssignmentWordService(request: IWordSequenceChange): Observable<BaseResponse<string, IWordSequenceChange>> {
    return this.http.post(AssignmentUrls.sortAssignmentWord(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, IWordSequenceChange> = res;
        data.request = request;
        return data;
      })
    );
  }

  public copyAndArchiveAssignmentService(request: ICopyAndArchiveRequest): Observable<BaseResponse<string, ICopyAndArchiveRequest>> {
    return this.http.post(AssignmentUrls.copyAndArchiveAssignment(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, ICopyAndArchiveRequest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getAllIntroSlideByAssignmentIdService(): Observable<BaseResponse<IntroSlide[], number>> {
    return this.http.get(AssignmentUrls.getAllIntroSlideByAssignmentId(this.baseUrl)).pipe(
      map((res) => {
        const data: BaseResponse<IntroSlide[], number> = res;
        return data;
      })
    );
  }

  public addIntroSlideService(request: IntroSlide): Observable<BaseResponse<string, IntroSlide>> {
    return this.http.post(AssignmentUrls.addIntroSlide(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, IntroSlide> = res;
        data.request = request;
        return data;
      })
    );
  }

  public updateIntroSlideService(request: IntroSlide): Observable<BaseResponse<string, IntroSlide>> {
    return this.http.post(AssignmentUrls.updateIntroSlide(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, IntroSlide> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getIntroSlideByIdService(request: string): Observable<BaseResponse<IntroSlide, string>> {
    return this.http.get(AssignmentUrls.getIntroSlideById(this.baseUrl).replace(':slideId', request)).pipe(
      map((res) => {
        const data: BaseResponse<IntroSlide, string> = res;
        data.request = request;
        return data;
      })
    );
  }

  public deleteIntroSlideService(request: number): Observable<BaseResponse<string, number>> {
    return this.http.delete(AssignmentUrls.deleteIntroSlide(this.baseUrl).replace(':slideId', request?.toString()), request).pipe(
      map((res) => {
        const data: BaseResponse<string, number> = res;
        data.request = request;
        return data;
      })
    );
  }


  public getAllFeedbackQuestionsByAssignmentIdService(): Observable<BaseResponse<FeedBackQuestion[], number>> {
    return this.http.get(AssignmentUrls.getAllFeedbackQuestionsByAssignmentId(this.baseUrl)).pipe(
      map((res) => {
        const data: BaseResponse<FeedBackQuestion[], number> = res;
        return data;
      })
    );
  }

  public addFeedbackQuestionService(request: FeedBackQuestion): Observable<BaseResponse<string, FeedBackQuestion>> {
    return this.http.post(AssignmentUrls.addFeedbackQuestion(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, FeedBackQuestion> = res;
        data.request = request;
        return data;
      })
    );
  }

  public updateFeedbackQuestionService(request: FeedBackQuestion): Observable<BaseResponse<string, FeedBackQuestion>> {
    return this.http.post(AssignmentUrls.updateFeedbackQuestion(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, FeedBackQuestion> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getFeedbackQuestionByIdService(request: string): Observable<BaseResponse<FeedBackQuestion, string>> {
    return this.http.get(AssignmentUrls.getFeedbackQuestionById(this.baseUrl).replace(':Id', request)).pipe(
      map((res) => {
        const data: BaseResponse<FeedBackQuestion, string> = res;
        data.request = request;
        return data;
      })
    );
  }

  public removeFeedbackQuestionService(request: number): Observable<BaseResponse<string, number>> {
    return this.http.delete(AssignmentUrls.removeFeedbackQuestion(this.baseUrl).replace(':FeedbackQuestionId', request?.toString()), request).pipe(
      map((res) => {
        const data: BaseResponse<string, number> = res;
        data.request = request;
        return data;
      })
    );
  }

  public addAssignmentAttachment(
    request: AddAssignmentAttachment
  ): Observable<BaseResponse<string, AddAssignmentAttachment>> {
    return this.http
      .post(AssignmentUrls.addAssignmentAttachment(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, AddAssignmentAttachment> = res;
          return data;
        })
      );
  }

  public removeAssignmentAttachments(
    request: RemoveAttachment
  ): Observable<BaseResponse<string, RemoveAttachment>> {
    return this.http
      .post(AssignmentUrls.removeAssignmentAttachment(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, RemoveAttachment> = res;
          return data;
        })
      );
  }

  public sortAssignmentAttachmentService(request: IAttachmentSequenceChange): Observable<BaseResponse<string, IAttachmentSequenceChange>> {
    return this.http.post(AssignmentUrls.sortAssignmentAttachment(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, IAttachmentSequenceChange> = res;
        data.request = request;
        return data;
      })
    );
  }

  public importAssignmentWords(request: ImportAssignmentWords): Observable<BaseResponse<string, ImportAssignmentWords>> {
    return this.http.post(AssignmentUrls.importAssignmentWords(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, ImportAssignmentWords> = res;
        data.request = request;
        return data;
      })
    );
  }

  public exportAssignmentWords(AssignmentId: number): Observable<Blob> {
    return this.httpClient
      .get(AssignmentUrls.exportAssignmentWords(this.baseUrl).replace(':AssignmentId', AssignmentId.toString()), {
        headers: {
          Authorization: this.http.getCurrentToken(),
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        responseType: 'blob'
      })
      .pipe(
        map((res) => {
          const data: Blob = res;
          return data;
        })
      );
  }

}
