import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {distinctUntilChanged} from 'rxjs/operators';
import {isEqual} from 'lodash-es';
import {getSessionToken, getToken, IAppState} from '@mptl/models';
import {ActivatedRoute} from "@angular/router";

@Injectable()
export class HttpWrapperService {
  private token: string | null;
  private sessionToken: string | null;

  constructor(private _http: HttpClient, private store: Store<IAppState>,
              private activatedRoute: ActivatedRoute) {
    this.store
      .pipe(select(getToken), distinctUntilChanged(isEqual))
      .subscribe((res) => {
        if (res) {
          this.token = res;
        } else {
          this.token = null;
        }
      });
    this.store
      .pipe(select(getSessionToken), distinctUntilChanged(isEqual))
      .subscribe((res) => {
        if (res) {
          this.sessionToken = res;
        } else {
          this.sessionToken = null;
        }
      });
  }

  public getCurrentToken():string{
    if (this.token) {
      if (this.sessionToken) {
       return `Bearer ${this.sessionToken}`;
      } else {
       return `Bearer ${this.token}`
      }
    }
  }

  get Token(): string {
    return <string>this.token;
  }

  public get = (url: string, params?: any, options?: any): Observable<any> => {
    options = this.prepareOptions(options);
    options.params = params;
    return this._http.get(url, options);
  };

  public post = (
    url: string,
    body: any,
    options?: any,
    addcontentType: boolean = true
  ): Observable<any> => {
    options = this.prepareOptions(options, addcontentType);
    return this._http.post(url, body, options);
  };
  public put = (url: string, body: any, options?: any): Observable<any> => {
    options = this.prepareOptions(options);
    return this._http.put(url, body, options);
  };
  public delete = (
    url: string,
    params?: any,
    options?: any
  ): Observable<any> => {
    options = this.prepareOptions(options);
    options.search = this.objectToParams(params);
    return this._http.delete(url, options);
  };
  public patch = (url: string, body: any, options?: any): Observable<any> => {
    options = this.prepareOptions(options);
    return this._http.patch(url, body, options);
  };

  public prepareOptions(options: any, addcontentType: boolean = true): any {
    options = options || {};
    if (!options.headers) {
      options.headers = {} as any;
    }
    // options.withCredentials = true;
    options.headers['cache-control'] = 'no-cache';
    if (!options.headers['Content-Type'] && addcontentType) {
      options.headers['Content-Type'] = 'application/json';
    }
    options.headers['timezone'] =
      Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (!options.headers['Accept']) {
      options.headers['Accept'] = 'application/json';
    }
    if (!options.headers['Authorization']) {
      if (this.token) {
        if (this.sessionToken) {
          options.headers['Authorization'] = `Bearer ${this.sessionToken}`;
        } else {
          options.headers['Authorization'] = `Bearer ${this.token}`;
        }
      }
    }
    options.headers = new HttpHeaders(options.headers);
    return options;
  }

  public isPrimitive(value: string | null | any) {
    return (
      value == null ||
      (typeof value !== 'function' && typeof value !== 'object')
    );
  }

  public objectToParams(object: any = {}) {
    return Object.keys(object)
      .map((value) => {
        const objectValue = this.isPrimitive(object[value])
          ? object[value]
          : JSON.stringify(object[value]);
        return `${value}=${objectValue}`;
      })
      .join('&');
  }
}
