import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {MatInput} from '@angular/material/input';

@Directive({
  selector: '[matInputAutofocus]',
})
export class AutofocusDirective implements OnInit {

  @Input()
  autofocusSelectValue = false;

  constructor(
    private matInput: MatInput,
    private elRef: ElementRef<any>,
  ) {
  }

  ngOnInit(): void {
    const clear = setTimeout(() => {
      this.matInput.focus();
      const input = this.elRef.nativeElement;

      if (this.autofocusSelectValue) {
        this.elRef.nativeElement.setSelectionRange(0, input?.value?.length);
      }
      clearTimeout(clear);
    });
  }

}
