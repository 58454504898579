import {AssignmentSubTypes, AssignmentTypes} from '../assignment/assignment';

export class StudentModel {
  id: number;
  firstName: string;
  lastName: string;
  code: string;
  position: StudentHandPosition;
  groupIds : number[];
}

export class StudentLogin {
  qrCode: string;
  code: string;
  isQrCode: boolean;
}

export class DemoStudentLoginReq {
  code: string;
  studentCode: string;
}

export interface TodayTests {
  upcomingTests: IStudentTest[];
  todayTests: IStudentTest[];
  practiceTests: IStudentTest[];
  schoolStartTime: Date;
  schoolEndTime: Date;
}

export interface IStudentTest {
  testId: number;
  testDayId: number;
  testName: string;
  status: string;
  assignmentName: string;
  assignmentType: AssignmentTypes;
  sequence?: number;
  isTestDay?: boolean;
  date?: Date;
  alreadyStartedTestId?: number;
  subTypeName: AssignmentSubTypes;
  upcomingDate?: Date;
  upcomingDateLocal?: Date;
  showCounter: boolean;
  pendingHoursCounter: number;
  pendingMinuteCounter: number;
  pendingSecondsCounter: number;
}

export enum StudentHandPosition {
  RightHanded = "RightHanded",
  LeftHanded = "LeftHanded"
}

export class RemoveStudent {
  studentId: number;
  forceDelete: boolean;
}
export class MoveStudentToGroup{
  studentId : number;
  moveToGroupId : number;
  groupType: string;
  oldGroupIds: number[];
}
export class MoveToGroupModel{
  id: number;
  type: string;
}

