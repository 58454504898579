import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpEvent, HttpProgressEvent, HttpSentEvent,} from '@angular/common/http';
import {FileUrls} from './apiUrls/file-urls';
import {BaseFileResponse, BaseResponse, UploadFileResponse} from '@mptl/models';
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";

@Injectable()
export class FileService {
  uploadImageOnLocal(
    file: FormData
  ): Observable<
    HttpEvent<
      HttpSentEvent | HttpProgressEvent | BaseResponse<BaseFileResponse, null>
    >
  > {
    return this.http.post<
      HttpSentEvent | HttpProgressEvent | BaseResponse<BaseFileResponse, null>
    >(FileUrls.uploadImageOnLocal(this.baseUrl), file, {
      reportProgress: true,
      observe: 'events',
    });
  }

  public uploadImageOnLocalFromUrl(request: string): Observable<BaseResponse<BaseFileResponse, string>> {
    return this.http.post(FileUrls.uploadImageOnLocalFromUrl(this.baseUrl), {url: request}).pipe(
      map((res: BaseResponse<BaseFileResponse, string>) => {
        const data: BaseResponse<BaseFileResponse, string> = res;
        return data;
      })
    );
  }

  public uploadFileOnLocalService(request: FormData): Observable<BaseResponse<UploadFileResponse, FormData>> {
    // @ts-ignore
    return this.http.post(FileUrls.uploadFileOnLocal(this.baseUrl), request, {}, false).pipe(
      map(res => {
        // @ts-ignore
        const data: BaseResponse<UploadFileResponse, FormData> = res;
        data.request = request;
        return res;
      })
    );
  }

  constructor(
    private http: HttpClient,
    @Inject('BASE_URL') private baseUrl: string
  ) {
  }
}
