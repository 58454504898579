import {Inject, Injectable} from '@angular/core';
import {HttpWrapperService} from './httpWrapper';
import {
  BaseFilterRequest,
  BaseResponse,
  GroupModel,
  IGroupDropDown,
  PaginatedResponse,
  RemoveGroup,
} from '@mptl/models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GroupUrls} from './apiUrls/group-urls';

@Injectable()
export class GroupService {
  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any
  ) {
  }

  public createGroup(
    request: GroupModel
  ): Observable<BaseResponse<string, GroupModel>> {
    return this.http.post(GroupUrls.createGroup(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, GroupModel> = res;
        return data;
      })
    );
  }

  public updateGroup(
    request: GroupModel
  ): Observable<BaseResponse<string, GroupModel>> {
    return this.http.post(GroupUrls.updateGroup(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, GroupModel> = res;
        return data;
      })
    );
  }

  public getGroupById(
    id: string
  ): Observable<BaseResponse<GroupModel, string>> {
    return this.http
      .get(GroupUrls.getGroupById(this.baseUrl).replace(':GroupId', id))
      .pipe(
        map((res) => {
          const data: BaseResponse<GroupModel, string> = res;
          return data;
        })
      );
  }

  public removeGroup(request: RemoveGroup): Observable<BaseResponse<string, RemoveGroup>> {
    return this.http
      .post(GroupUrls.removeGroup(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, RemoveGroup> = res;
          return data;
        })
      );
  }

  public getGroupList(
    request: BaseFilterRequest
  ): Observable<BaseResponse<PaginatedResponse<GroupModel[]>, BaseFilterRequest>> {
    return this.http.post(GroupUrls.getGroupList(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<PaginatedResponse<GroupModel[]>,
          BaseFilterRequest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getGroupListForDropdown(
    excludeDemo?: boolean
  ): Observable<BaseResponse<IGroupDropDown[], string>> {
    return this.http
      .get(
        GroupUrls.getGroupListForDropdown(this.baseUrl).replace(
          'NO',
          excludeDemo ? 'YES' : 'NO'
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<IGroupDropDown[], string> = res;
          return data;
        })
      );
  }

  public createDefaultGroup(request: string): Observable<BaseResponse<string, string>> {
    return this.http.post(GroupUrls.createDefaultGroup(this.baseUrl), { groupType: request }).pipe(
      map((res) => {
        const data: BaseResponse<string, string> = res;
        return data;
      })
    );
  }
}
