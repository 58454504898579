import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@mptl/web/services';
import { WebViewReleaseNoteModule } from '@mptl/web/view-release-note';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () =>
      import('@mptl/web/public-home-layout').then(
        (p) => p.PublicHomeLayoutModule
      ),
  },
  {
    path: 'intro',
    loadChildren: () =>
      import('@mptl/web/public-intro').then(
        (p) => p.PublicIntroModule
      ),
  },
  {
    path: 'terms',
    loadChildren: () =>
      import('@mptl/web/terms-of-service').then(
        (p) => p.WebTermsOfServiceModule
      ),
  },
  {
    path: 'PrivacyPolicy',
    loadChildren: () =>
      import('@mptl/web/privacy-policy').then(
        (p) => p.WebPrivacyPolicyModule
      ),
  },
  {
    path: 'confirm-email',
    loadChildren: () =>
      import('@mptl/web/confirm-email').then((p) => p.ConfirmEmailModule),
  },
  {
    path: 'admin-layout',
    loadChildren: () =>
      import('@mptl/web/admin-layout').then((p) => p.AdminLayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'student-layout',
    loadChildren: () =>
      import('@mptl/web/student-layout').then((p) => p.StudentLayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadChildren: () => import('@mptl/web/login').then((p) => p.LoginModule),
  },
  {
    path: 'register',
    loadChildren: () => import('@mptl/web/signup').then((p) => p.SignupModule),
  },
  {
    path: 'complete-registration/:key',
    loadChildren: () => import('@mptl/web/complete-registration').then((p) => p.CompleteRegistrationModule),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('@mptl/web/forgot-password').then((p) => p.ForgotPasswordModule),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('@mptl/web/reset-password').then((p) => p.ResetPasswordModule),
  },
  {
    path: 'student-login',
    loadChildren: () =>
      import('@mptl/web/student-login').then((p) => p.StudentLoginModule),
  },
  {
    path: 'start-demo',
    loadChildren: () =>
      import('@mptl/web/start-demo').then((p) => p.WebStartDemoModule),
  },
  {
    path: 'bypass-login',
    loadChildren: () =>
      import('@mptl/web/start-demo').then((p) => p.WebStartDemoModule),
  },
  {
    path: 'demo-links',
    loadChildren: () =>
      import('@mptl/web/demo-links').then((p) => p.WebDemoLinksModule),
  },
  {
    path: 'certificate',
    loadChildren: () =>
      import('@mptl/web/certificate').then((p) => p.WebCertificateModule),
  },
  {path: 'public-home-layout', redirectTo: '', pathMatch: 'full'},
  {path: '**', redirectTo: ''},
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules,}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
