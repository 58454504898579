import {Inject, Injectable, NgZone} from '@angular/core';
import {ApplicationInsights, ITelemetryItem} from '@microsoft/applicationinsights-web';
import {SeverityLevel} from '@microsoft/applicationinsights-common';

@Injectable()
export class AppInsightsLoggingService {
  appInsights: ApplicationInsights;
  userName: string;
  userId: string;

  constructor(
    @Inject('ENVIRONMENT_NAME')
    private environmentName: 'local' | 'development' | 'production',
    @Inject('APP_INSIGHT_INSTRUMENT_KEY') private instrumentKey: string,
    @Inject('APP_VERSION') private appVersion: string,
    private zone: NgZone,
  ) {
  }

  initAppInsight() {
    if (!this.appInsights) {
      this.appInsights = new ApplicationInsights({
        config: {
          instrumentationKey: this.instrumentKey,
          appId: 'MyPathToLearning - WEB - ' + this.environmentName + '-' + this.appVersion,
          enableAutoRouteTracking: false,
          autoTrackPageVisitTime: true,
          disableXhr: false,
          disableAjaxTracking: false,
          disableFetchTracking: false,
          disableExceptionTracking: false,
          isBeaconApiDisabled: false,
          isRetryDisabled: false,
        },
      });
      this.appInsights?.loadAppInsights();
      let telemetryInitializer = (envelope: ITelemetryItem) => {
        envelope.name = 'MyPathToLearning - WEB - ' + this.environmentName + '-' + this.appVersion;
        // envelope.data = {
        //   'WebAppName': 'MyPathToLearning - WEB - ' + this.environmentName + '-' + this.appVersion,
        // }
        envelope.tags['WebAppName'] =
          'MyPathToLearning - WEB - ' + this.environmentName + '-' + this.appVersion;
      };
      this.appInsights.addTelemetryInitializer(telemetryInitializer);
    }
  }

  addLoggedInUser(userName: string, userId: string) {
    this.userName = userName;
    this.userId = userId;
    this.appInsights?.setAuthenticatedUserContext(userName, userId);
  }

  removeLoggedInUser() {
    this.userName = '';
    this.userId = '';
    this.appInsights?.clearAuthenticatedUserContext();
  }

  logPageView(
    name?: string,
    url?: string,
    properties?: { [key: string]: any }
  ) {
    // option to call manually
    this.zone.runOutsideAngular(() => {
      if(!this.appInsights) {
        this.initAppInsight();
      }
      this.appInsights?.trackPageView({
        name: name,
        uri: url,
        properties: {
          ...properties,
          duration: 0,
        },
      });
    })
  }

  logEvent(name: string, properties?: { [key: string]: any }) {
    this.zone.runOutsideAngular(() => {
      properties = {
        appName: 'MPTL-WEB-' + this.environmentName + '-' + this.appVersion,
        userName: this.userName,
        userId: this.userId,
        ...properties,
      };
      if(!this.appInsights) {
        this.initAppInsight();
      }
      this.appInsights?.trackEvent({name: name}, properties);
    })
  }

  logMetric(
    name: string,
    average: number,
    properties?: { [key: string]: any }
  ) {
    this.zone.runOutsideAngular(() => {
      this.appInsights?.trackMetric({name: name, average: average}, properties);
    })
  }

  logException(exception: Error, severityLevel?: SeverityLevel) {
    this.zone.runOutsideAngular(() => {
      if(!this.appInsights) {
        this.initAppInsight();
      }
      this.appInsights?.trackException({
        properties: {
          appName: 'MyPathToLearning - WEB - ' + this.environmentName + '-' + this.appVersion,
          userName: this.userName,
          userId: this.userId,
        },
        exception: exception,
        severityLevel: severityLevel,
      });
    })
  }

  logTrace(message: string, properties?: { [key: string]: any }) {
    this.zone.runOutsideAngular(() => {
      if(!this.appInsights) {
        this.initAppInsight();
      }
      this.appInsights?.trackTrace(
        {message: message},
        {
          appName: 'MyPathToLearning - WEB - ' + this.environmentName + '-' + this.appVersion,
          userName: this.userName,
          userId: this.userId,
          ...properties,
        }
      );
    })
  }
}
