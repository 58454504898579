<div class="no-internet-container p-3">
  <div class="d-flex justify-content-center">
    <img
      width="150px"
      src="/assets/img/no-internet-icon.png"
      alt="no-internet-image"
    />
  </div>
  <div class="no-internet-text-content mt-3">
    <h2 class="text-center m-0 mb-2">No Internet Connection</h2>
    <small class="text-center" style="color: gray"
      >Please check your internet connection and try again</small
    >
  </div>
</div>
