import { createUrl } from './base-url';

export const PracticeTestUrls = {
  createTest: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/createTest'),
  updateTest: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/updateTest'),
  deleteTest: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/deleteTest/:testId'),
  getTestById: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/getTestById/:testId'),
  getAllTestList: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/getAllTestList'),
  toggleCloseTest: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/toggleCloseTest/:testId'),
  startPracticeTest: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/startPracticeTest/:testId'),
  getStudentPracticeTestDetails: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/getStudentPracticeTestDetails'),
  createTestFromPractice: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/createTestFromPractice'),
  getAllStudentAtteptsFromTestId: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/getAllStudentAtteptsFromTestId'),
  getPracticeTestStudentAttemptsQuery: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/getPracticeTestStudentAttemptsQuery'),
  changePracticeTestDirectory: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/changePracticeTestDirectory'),
  getPracticeTestConfig: (baseUrl: string) => createUrl(baseUrl, 'PracticeTest/getPracticeTestConfig/:testId'),
};
