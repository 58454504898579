import {Inject, Injectable} from "@angular/core";
import {HttpWrapperService} from "@mptl/web/services";
import {HttpClient} from "@angular/common/http";
import {BaseFilterRequest, BaseResponse, PaginatedResponse, TagModel} from "@mptl/models";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {TagUrls} from "./apiUrls/tag-urls";

@Injectable()
export class TagService {
  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any,
    private httpClient: HttpClient
  ) {
  }

  public createTag(
    request: TagModel
  ): Observable<BaseResponse<string, TagModel>> {
    return this.http
      .post(TagUrls.createTag(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, TagModel> = res;
          return data;
        })
      );
  }

  public updateTag(
    request: TagModel
  ): Observable<BaseResponse<string, TagModel>> {
    return this.http
      .post(TagUrls.updateTag(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, TagModel> = res;
          return data;
        })
      );
  }


  public getTagById(
    id: string
  ): Observable<BaseResponse<TagModel, string>> {
    return this.http
      .get(TagUrls.getTagById(this.baseUrl).replace(':id', id))
      .pipe(
        map((res) => {
          const data: BaseResponse<TagModel, string> = res;
          return data;
        })
      );
  }

  public deleteTag(request: number): Observable<BaseResponse<string, number>> {
    return this.http
      .delete(TagUrls.deleteTag(this.baseUrl).replace(':id', request.toString()))
      .pipe(
        map((res) => {
          const data: BaseResponse<string, number> = res;
          return data;
        })
      );
  }

  getAllTags(
    filterRequest: BaseFilterRequest
  ): Observable<BaseResponse<PaginatedResponse<TagModel[]>, BaseFilterRequest>> {
    return this.http
      .post(TagUrls.getAllTags(this.baseUrl), filterRequest)
      .pipe(
        map((res) => {
          const data: BaseResponse<PaginatedResponse<TagModel[]>,
            BaseFilterRequest> = res;
          data.request = filterRequest;
          return data;
        })
      );
  }

  public getAllTagsDropDown(): Observable<BaseResponse<TagModel[], string>> {
    return this.http
      .get(TagUrls.getAllTagsDropDown(this.baseUrl))
      .pipe(
        map((res) => {
          const data: BaseResponse<TagModel[], string> = res;
          return data;
        })
      );
  }
}
