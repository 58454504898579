import {createUrl} from './base-url';

export const TestUrls = {
  createTest: (baseUrl: string) => createUrl(baseUrl, 'Test/createTest'),
  updateTest: (baseUrl: string) => createUrl(baseUrl, 'Test/updateTest'),
  createPracticeTestFromTest: (baseUrl: string) => createUrl(baseUrl, 'Test/createPracticeTestFromTest'),
  getTestById: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getTestById/:testId'),
  deleteTest: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/deleteTest/:testId'),
  copyTestLink: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/copyUserTest/:testId'),
  getAllTestList: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getAllTestList'),
  clearDemoTestLinks: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/deleteAllCopiedLinks'),
  startStudentTest: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/startStudentTest'),
  saveStudentTestWordAttempt: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/saveStudentTestWordAttempt'),
  getTestDayListByTestId: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getTestDayListByTestId?TestId=:TestId'),
  getTestDayResult: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getTestDayResult'),
  getStudentTestDayDetailById: (baseUrl: string) =>
    createUrl(
      baseUrl,
      'Test/getStudentTestDayDetailById?StudentTestDayId=:StudentTestDayId'
    ),
  getStudentTestWordAttemptDetails: (baseUrl: string) =>
    createUrl(
      baseUrl,
      'Test/getStudentTestWordAttemptDetails?StudentTestDayId=:StudentTestDayId&AssignmentWordId=:AssignmentWordId'
    ),
  getStudentTestWordAttemptData: (baseUrl: string) =>
    createUrl(
      baseUrl,
      'Test/getStudentTestWordAttemptData?AttemptId=:AttemptId'
    ),
  saveTempAttempt: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/saveTempAttempt'),
  getStudentTestDayWordAttempt: (baseUrl: string) =>
    createUrl(
      baseUrl,
      'Test/getStudentTestDayWordAttempt?AssignmentWordId=:AssignmentWordId&TestDayId=:TestDayId&ShowAlreadyGraded=:ShowAlreadyGraded'
    ),
  getTestFeedbackResultByTestDayId: (baseUrl: string) =>
    createUrl(
      baseUrl,
      'Test/getTestFeedbackResultByTestDayId/:TestDayId'
    ),
  saveWordAttemptReview: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/saveWordAttemptReview'),
  getTestDashboardData: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getTestDashbordData'),
  getTestAssignmentChartData: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getTestAssignmentChartData'),
  getTestDropdownList: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/GetTestDropdownList'),
  getStudentGradeBook: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/GetStudentGradeBook'),
  GetTestGradeData: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/GetTestGradeData'),
    GetTestGradeDataByGroupOfStudent:(baseUrl:string) =>
    createUrl(baseUrl,'Test/GetTestGradeDataByGroupOfStudent'),
  getTestDayConfigurations: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getTestDayConfigurations'),
  getAllTestDayConfigurations: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getAllTestDayConfiguration/:id'),
  updateTestDayConfigurations: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/updateTestDayConfigurations'),
  generateDummyAttemptOnTestDay: (baseUrl: string) =>
    createUrl(
      baseUrl,
      'Test/generateDummyAttemptOnTestDay?TestDayId=:TestDayId'
    ),
  generateDummyGradingOnTestDay: (baseUrl: string) =>
    createUrl(
      baseUrl,
      'Test/generateDummyGradingOnTestDay?TestDayId=:TestDayId'
    ),
  getStudentFluencyChartDetails: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getStudentFluencyChartDetails'),
  testdetailForTestResults: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/testdetailForTestResults/:TestId'),
  changeTestDirectory: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/changeTestDirectory'),
  getTestDetailsFromEncryptedToken: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getTestDetailsFromEncryptedToken'),
  getPendingTestsForGrading: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getAllUngradedTestsQuery'),
  getIntroSlidesForTest: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/getAllIntroSlideByAssignmentId?testId=:testId'),
  completeTestIntroSlide: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/completeIntroSlide'),
  getFeedbackQuestionsForTest: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/getAllFeedbackQuestionsByAssignmentId?testId=:testId'),
  saveAssignmentFeedback: (baseUrl: string) =>
    createUrl(baseUrl, 'Assignment/saveAssignmentFeedbackData'),
  getAllDemoLinkList: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getAllDemoLinks'),
  getStudentTestResultByStudentId: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getStudentTestResultByStudentId'),
  getTestDayFeedbackResultByStudentId: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getTestDayFeedbackResultByStudentId'),
  getTestBasicDetail: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getTestBasicDetail/:TestDayId'),
  getAllAttemptsPaginated: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getAllAttemptsPaginated'),
  removeAttemptFromVideoConversionList: (baseUrl: string,attemptId: number) =>
    createUrl(baseUrl, 'Test/removeAttemptFromVideoConversionList/'+attemptId),
  getStudentNameFromTestId: (baseUrl: string) =>
    createUrl(baseUrl, 'Test/getStudentNameFromTestId/:testId'),
};
