import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class ToastService {
  constructor(private _snackBar: MatSnackBar) {}

  private show(
    textOrTpl: string | TemplateRef<any>,
    data = null,
    options: { duration: number; panelClass?: string } = { duration: 5000 }
  ) {
    if (textOrTpl instanceof TemplateRef) {
      this._snackBar.openFromTemplate(textOrTpl, {
        ...options,
        data: data,
        duration: options.duration,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    } else {
      this._snackBar.open(textOrTpl, '', {
        ...options,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      });
    }
  }

  public error(textOrTpl: string | TemplateRef<any>, data = null) {
    this.show(textOrTpl, data, { duration: 5000, panelClass: 'error-toast' });
  }

  public warn(textOrTpl: string | TemplateRef<any>, data = null) {
    this.show(textOrTpl, data, { duration: 5000, panelClass: 'warn-toast' });
  }
  public success(textOrTpl: string | TemplateRef<any>, data = null) {
    this.show(textOrTpl, data, { duration: 5000, panelClass: 'success-toast' });
  }
}
