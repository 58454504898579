import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef,} from '@angular/material/bottom-sheet';
import {Platform} from '@angular/cdk/platform';
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
  selector: 'mptl-app-install-now',
  templateUrl: './app-install-now.component.html',
  styleUrls: ['./app-install-now.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppInstallNowComponent implements OnInit {
  isIpad = `(min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2)`
  isIpadPro = `(min-device-width: 834px) and (max-device-width: 1112px) and (-webkit-min-device-pixel-ratio: 2)`

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<AppInstallNowComponent>,
    public plateForm: Platform,
    public breakPointObserver: BreakpointObserver,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: { isUpdateMode: boolean }
  ) {
  }

  ngOnInit(): void {
  }

  close(result?: boolean): void {
    this._bottomSheetRef.dismiss(result);
  }
}
