import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BackButtonDirective} from './directives/back-button.directive';
import {RoundNumberToTwoDigitPipe} from './directives/round-number-to-two-digit.pipe';
import {LogClickDirective} from './directives/log-click.directive';
import {MatDialogModule} from '@angular/material/dialog';
import {TimeFromDecimalPipe} from './directives/timeFromDecimal.pipe';
import {AutofocusDirective} from "./directives/mat-input-autofocus.directive";
import {NumberDirective} from "./directives/numbers-only.directive";
import {SafePipe} from "./directives/safe.pipe";

@NgModule({
  declarations: [
    BackButtonDirective,
    LogClickDirective,
    RoundNumberToTwoDigitPipe,
    TimeFromDecimalPipe,
    AutofocusDirective,
    NumberDirective,
    SafePipe
  ],
  imports: [CommonModule, MatDialogModule],
  exports: [
    BackButtonDirective,
    RoundNumberToTwoDigitPipe,
    LogClickDirective,
    TimeFromDecimalPipe,
    AutofocusDirective,
    NumberDirective,
    SafePipe
  ],
})
export class UtilitiesModule {
}
