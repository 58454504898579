import {Inject, Injectable} from '@angular/core';
import {HttpWrapperService} from './httpWrapper';
import { BaseFilterRequest, BaseResponse, PaginatedResponse, ReleaseNote, ReleaseNoteUserReq } from '@mptl/models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ReleaseNoteUrls} from "./apiUrls/release-note-urls";

@Injectable()
export class ReleaseNoteService {
  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any
  ) {
  }

  public getReleaseAllNote(
    request: BaseFilterRequest
  ): Observable<BaseResponse<PaginatedResponse<ReleaseNote[]>, BaseFilterRequest>> {
    return this.http.post(ReleaseNoteUrls.getReleaseAllNote(this.baseUrl.replace(`/api`, '')), request).pipe(
      map((res) => {
        const data: BaseResponse<PaginatedResponse<ReleaseNote[]>, BaseFilterRequest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public createReleaseNote(request: ReleaseNote): Observable<BaseResponse<string, ReleaseNote>> {
    return this.http.post(ReleaseNoteUrls.createReleaseNote(this.baseUrl.replace(`/api`, '')), request).pipe(
      map((res) => {
        const data: BaseResponse<string, ReleaseNote> = res;
        data.request = request;
        return data;
      })
    );
  }

  public updateReleaseNote(request: ReleaseNote): Observable<BaseResponse<string, ReleaseNote>> {
    return this.http.post(ReleaseNoteUrls.updateReleaseNote(this.baseUrl.replace(`/api`, '')), request).pipe(
      map((res) => {
        const data: BaseResponse<string, ReleaseNote> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getReleaseNoteById(request: number): Observable<BaseResponse<ReleaseNote, number>> {
    return this.http
      .get(
        ReleaseNoteUrls.getReleaseNoteById(this.baseUrl.replace(`/api`, '')).replace(':Id', request?.toString())
      ).pipe(
        map((res) => {
          const data: BaseResponse<ReleaseNote, number> = res;
          data.request = request;
          return data;
        })
      );
  }

  public getReleaseNoteByVersion(request: string): Observable<BaseResponse<ReleaseNote, string>> {
    return this.http
      .get(
        ReleaseNoteUrls.getReleaseNoteByVersion(this.baseUrl.replace(`/api`, '')).replace(':Version', request)
      ).pipe(
        map((res) => {
          const data: BaseResponse<ReleaseNote, string> = res;
          data.request = request;
          return data;
        })
      );
  }

  public deleteReleaseNote(request: number): Observable<BaseResponse<string, number>> {
    return this.http
      .delete(
        ReleaseNoteUrls.deleteReleaseNote(this.baseUrl.replace(`/api`, '')).replace(
          ':Id',
          request?.toString()
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<string, number> = res;
          data.request = request;
          return data;
        })
      );
  }
  public getCurrentVersionNo(): Observable<BaseResponse<string, undefined>> {
    return this.http
      .get(
        ReleaseNoteUrls.getCurrentVersionNo(this.baseUrl.replace(`/api`, ''))
      ).pipe(
        map((res) => {
          const data: BaseResponse<string, undefined> = res;
          return data;
        })
      );
  }

  public getReleaseNoteByUserId(request: ReleaseNoteUserReq): Observable<BaseResponse<ReleaseNote[], ReleaseNoteUserReq>> {
    return this.http
      .post(
        ReleaseNoteUrls.getReleaseNoteByUserId(this.baseUrl.replace(`/api`, '')), request).pipe(
        map((res) => {
          const data: BaseResponse<ReleaseNote[], ReleaseNoteUserReq> = res;
          data.request = request;
          return data;
        })
      );
  }
  public createReleaseNoteUserMapping(request: ReleaseNoteUserReq): Observable<BaseResponse<boolean, ReleaseNoteUserReq>> {
    return this.http.post(ReleaseNoteUrls.createReleaseNoteUserMapping(this.baseUrl.replace(`/api`, '')), request).pipe(
      map((res) => {
        const data: BaseResponse<boolean, ReleaseNoteUserReq> = res;
        data.request = request;
        return data;
      })
    );
  }
  public getCurrentVersionByUserId(request: ReleaseNoteUserReq): Observable<BaseResponse<string, ReleaseNoteUserReq>> {
    return this.http
      .post(
        ReleaseNoteUrls.getCurrentVersionByUserId(this.baseUrl.replace(`/api`, '')), request).pipe(
        map((res) => {
          const data: BaseResponse<string, ReleaseNoteUserReq> = res;
          data.request = request;
          return data;
        })
      );
  }

}
