import {AssignmentTypes} from "../assignment/assignment";

export class GroupModel {
  id: number;
  name: string;
  color: string;
  students: number[];
  totalStudent: number;
  groupType: AssignmentTypes;
  studentNames: { code: string; name: string }[];
}

export class StudentListDropDownModel {
  id: number;
  name: string;
  code?: string;
  sortName: string;
  groupId?: number;
}

export class RemoveGroup {
  groupId: number;
  forceDelete: boolean;
}
