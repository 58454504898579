import { Inject, Injectable } from '@angular/core';
import { HttpWrapperService } from './httpWrapper';
import {
  BaseResponse,
  CreateAssignment,
  IDownLoadFromMarketPlaceRequest,
  IMarketPlaceAssignment,
  MarketPlaceFilterRequest,
  PaginatedResponse,
  PublishMarketAssignment, UpdateMarketPlaceRequest
} from '@mptl/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MarketPlaceUrls } from './apiUrls/market-place-urls';

@Injectable()
export class MarketPlaceService {
  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any
  ) {
  }

  downloadFromMarketPlace(request: IDownLoadFromMarketPlaceRequest): Observable<BaseResponse<string, IDownLoadFromMarketPlaceRequest>> {
    return this.http.post(MarketPlaceUrls.downloadFromMarketPlace(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, IDownLoadFromMarketPlaceRequest> = res;
          data.request = request;
          return data;
        })
      );
  }

  public publishTeacherAssignmentToMarketPlace(request: PublishMarketAssignment): Observable<BaseResponse<string, PublishMarketAssignment>> {
    return this.http.post(MarketPlaceUrls.publishTeacherAssignmentToMarketPlace(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, PublishMarketAssignment> = res;
        data.request = request;
        return data;
      })
    );
  }

  public publishUnPublishMarketPlace(request: number): Observable<BaseResponse<string, number>> {
    return this.http.post(MarketPlaceUrls.publishUnPublishMarketPlace(this.baseUrl), { id: request }).pipe(
      map((res) => {
        const data: BaseResponse<string, number> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getAllMarketPlaceAssignment(request: MarketPlaceFilterRequest): Observable<BaseResponse<PaginatedResponse<IMarketPlaceAssignment[]>, MarketPlaceFilterRequest>> {
    return this.http.post(MarketPlaceUrls.getAllMarketPlaceAssignment(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<PaginatedResponse<IMarketPlaceAssignment[]>, MarketPlaceFilterRequest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getMarketPlaceAssignmentById(request: string): Observable<BaseResponse<CreateAssignment, string>> {
    return this.http.get(MarketPlaceUrls.getMarketPlaceAssignmentById(this.baseUrl).replace(':id', request)).pipe(
      map((res) => {
        const data: BaseResponse<CreateAssignment, string> = res;
        data.request = request;
        return data;
      })
    );
  }

  public archiveMarketPlaceAssignment(request: string): Observable<BaseResponse<string, string>> {
    return this.http.get(MarketPlaceUrls.archiveMarketPlaceAssignment(this.baseUrl).replace(':AssignmentId', request)).pipe(
      map((res) => {
        const data: BaseResponse<string, string> = res;
        data.request = request;
        return data;
      })
    );
  }

  public updateMarketPlaceAssignment(request: UpdateMarketPlaceRequest): Observable<BaseResponse<string, UpdateMarketPlaceRequest>> {
    return this.http.post(MarketPlaceUrls.updateMarketPlaceAssignment(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, UpdateMarketPlaceRequest> = res;
        data.request = request;
        return data;
      })
    );
  }

}
