import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';
import {ElementRef} from '@angular/core';
import {AssignmentTypes, AssignmentWords, OperatorConfig} from '@mptl/models';
import {isNaN, shuffle} from 'lodash-es';
import {GeneralUrls} from '@mptl/web/services';

export function deg2rad(deg: number) {
  return deg * (Math.PI / 180);
}

declare var localStorage: any;

export function getDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
) {
  let R = 6371; // Radius of the earth in km
  let dLat = deg2rad(lat2 - lat1); // deg2rad below
  let dLon = deg2rad(lon2 - lon1);
  let a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
    Math.cos(deg2rad(lat2)) *
    Math.sin(dLon / 2) *
    Math.sin(dLon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c; // Distance in km
  return d;
}

export function markFullFormAsDirty(formGroup: FormGroup) {
  //{1}
  Object.keys(formGroup.controls).forEach((field) => {
    //{2}
    const control = formGroup.get(field); //{3}
    if (control instanceof FormControl) {
      //{4}
      control.markAsDirty({onlySelf: true});
      control.markAsTouched();
      // control.updateValueAndValidity();
    } else if (control instanceof FormGroup) {
      //{5}
      markFullFormAsDirty(control); //{6}
    } else if (control instanceof FormArray) {
      control.controls.forEach((x) => {
        if (x instanceof FormControl) {
          x.markAsDirty({onlySelf: true});
          x.markAsTouched();
          // control.updateValueAndValidity();
        } else if (x instanceof FormGroup) {
          markFullFormAsDirty(x);
        }
      });
    }
  });
}

export function focusFirstInvalidControl(
  formGroup: FormGroup,
  elementRef: ElementRef
) {
  Object.keys(formGroup.controls).forEach((field) => {
    const control = formGroup.get(field);
    if (control instanceof FormControl) {
      if (control.invalid) {
        const invalidControl = elementRef.nativeElement.querySelector(
          '[formcontrolname="' + field + '"]'
        );
        invalidControl?.focus();
        return;
      }
    } else if (control instanceof FormGroup) {
      focusFirstInvalidControl(control, elementRef);
    } else if (control instanceof FormArray) {
      control.controls.forEach((x) => {
        if (x instanceof FormControl) {
          if (control.invalid) {
            const invalidControl = elementRef.nativeElement.querySelector(
              '[formcontrolname="' + field + '"]'
            );
            invalidControl?.focus();
            return;
          }
        } else if (x instanceof FormGroup) {
          focusFirstInvalidControl(x, elementRef);
        }
      });
    }
  });
}

export function RoundNumberToTwoDigit(num: number): number {
  return num ? +(Math.round(+(num + 'e+2')) + 'e-2') : 0.0;
}

export const urlRegex = '(https://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';


export const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');

  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12;
  }

  return `${hours}:${minutes}`;
};


export function hashCode(str) { // java String#hashCode
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    // tslint:disable-next-line:no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    // tslint:disable-next-line:no-bitwise
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
}

export function intToRGB(i) {
  let c = (i & 0x00FFFFFF)
    .toString(16)
    .toUpperCase();

  return '00000'.substring(0, 6 - c.length) + c;
}

export const minLengthArray = (min: number) => {
  return (c: AbstractControl): { [key: string]: any } => {
    if (c.value?.find(x => x?.value)?.value >= min)
      return null;

    return {MinLengthArray: true};
  };
};


export function generateEquation(operators: string, currentStartLimit: number, currentEndLimit: number, baseUrl: string): AssignmentWords[] {
  let possibleOperands = new Array<Array<number>>();
  let bank: AssignmentWords[] = [];
  if (currentStartLimit === currentEndLimit && currentStartLimit === 0) {
    return bank;
  }
  for (let i = currentStartLimit; i <= currentEndLimit; i++) {
    for (let j = currentStartLimit; j <= currentEndLimit; j++) {
      possibleOperands.push([i, j]);
    }
  }
  possibleOperands = shuffle(possibleOperands);
  const myOperators = shuffle(operators.split(',').map(s => s.trim()).filter(s => s.length) ?? []);
  let bankIsFull = false;
  for (let i = 0; i < possibleOperands?.length; i++) {
    for (let j = 0; j < myOperators?.length; j++) {
      const value = eval(possibleOperands[i][0] + myOperators[j] + possibleOperands[i][1]);
      if (value && !isNaN(value) && (value % 1 === 0) && (value > -1)) {
        bank.push({
          id: 0,
          answer: value,
          type: AssignmentTypes.MATH,
          word: possibleOperands[i][0] + myOperators[j] + possibleOperands[i][1],
          editMode: false,
          sounds: {
            [possibleOperands[i][0]?.toString()]: GeneralUrls.getSound(baseUrl).replace(':word', possibleOperands[i][0]?.toString()),
            [possibleOperands[i][1]?.toString()]: GeneralUrls.getSound(baseUrl).replace(':word', possibleOperands[i][1]?.toString()),
            [value?.toString()]: GeneralUrls.getSound(baseUrl).replace(':word', value)
          },
          sequence: 0,
          audioKey: '',
          imageKey: '',
          imageUrl: '',
          audioRecordingURL: ''
        });
        if (bank?.length >= 100) {
          bankIsFull = true;
          break;
        }
      }
    }
    if (bankIsFull) {
      break;
    }
  }
  bank = shuffle<AssignmentWords>(bank);
  return bank;
}


export function generateEquationFromChoice(operators: string, choice: number[]): AssignmentWords[] {
  let possibleOperands = new Array<Array<number>>();
  let bank: AssignmentWords[] = [];
  if (choice?.length === 0) {
    return bank;
  }
  for (let i = 0; i <= choice.length - 1; i++) {
    for (let j = 0; j <= choice.length - 1; j++) {
      possibleOperands.push([choice[i], choice[j]]);
    }
  }
  possibleOperands = shuffle(possibleOperands);
  const myOperators = shuffle(operators.split(',').map(s => s.trim()).filter(s => s.length) ?? []);
  possibleOperands.forEach(s => {
    myOperators.forEach(operator => {
      const value = eval(s[0] + operator + s[1]);
      if (value && !isNaN(value) && (value % 1 === 0) && (value > -1) && !(operator === '/' && value === 1)) {
        bank.push({
          id: 0,
          answer: value,
          type: AssignmentTypes.MATH,
          word: s[0] + operator + s[1],
          editMode: false,
          sequence: 0,
          audioKey: '',
          audioRecordingURL: '',
          imageKey: '',
          imageUrl: ''
        });
      }
    });
  });
  bank = shuffle<AssignmentWords>(bank);
  return bank;
}

export function generateEquationForDifferentLimit(operators: OperatorConfig[], baseUrl: string): AssignmentWords[] {

  let bank: AssignmentWords[] = [];
  let bankIsFull = false;
  let equCount = Number((100 / operators.length).toFixed());
  let operCount = equCount;
  for (let q = 0; q < operators?.length; q++) {
    let possibleOperands = new Array<Array<number>>();
    if (operators[q].operandStartLimit === operators[q].operandEndLimit && operators[q].operandStartLimit === 0) {
      return bank;
    }
    while (possibleOperands.length < equCount) {
      possibleOperands.push([getRandomInt(operators[q].operandStartLimit, operators[q].operandEndLimit), getRandomInt(operators[q].operandStartLimit, operators[q].operandEndLimit)])
    }
    const myOperator = operators[q].operator;
    if (possibleOperands?.length > equCount) {
      possibleOperands = possibleOperands.splice(0, Math.floor(equCount));
    }
    for (let i = 0; i < possibleOperands?.length; i++) {
      const value = eval(possibleOperands[i][0] + myOperator + possibleOperands[i][1]);
      if (value && !isNaN(value) && (value % 1 === 0 || operators[q].isAllowDecimal) && (value > -1 || operators[q].isAllowNegative)) {
        let word = possibleOperands[i][0] + myOperator + possibleOperands[i][1];
        if (operators[q].operator === '+' || operators[q].operator === '*') {
          word = possibleOperands[i][0] > possibleOperands[i][1]
            ? possibleOperands[i][0] + myOperator + possibleOperands[i][1] : possibleOperands[i][1] + myOperator + possibleOperands[i][0];
        }
        bank.push({
          id: 0,
          answer: value % 1 === 0 ? value : RoundNumberToTwoDigit(+value),
          type: AssignmentTypes.MATH,
          word: word,
          editMode: false,
          sounds: {
            [possibleOperands[i][0]?.toString()]: GeneralUrls.getSound(baseUrl).replace(':word', possibleOperands[i][0]?.toString()),
            [possibleOperands[i][1]?.toString()]: GeneralUrls.getSound(baseUrl).replace(':word', possibleOperands[i][1]?.toString()),
            [value?.toString()]: GeneralUrls.getSound(baseUrl).replace(':word', value)
          },
          sequence: 0,
          audioKey: '',
          imageKey: '',
          imageUrl: '',
          audioRecordingURL: ''
        });
      }
    }
    // if (bank?.length >= operCount && operCount < 100) {
    //   operCount = operCount + equCount;
    //   break;
    // } else
    if (bank?.length >= 100) {
      bankIsFull = true;
      break;
    }
    if (bankIsFull) {
      break;
    }
  }
  bank = shuffle<AssignmentWords>(bank);
  console.log(bank);
  return bank;

}


export function base64toBlob(b64: string): Blob {
  const byteCharacters = atob(b64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray]);
  return blob;
}

export function base64ArrayBuffer(arrayBuffer) {
  let base64 = '';
  const encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';

  const bytes = new Uint8Array(arrayBuffer);
  const byteLength = bytes.byteLength;
  const byteRemainder = byteLength % 3;
  const mainLength = byteLength - byteRemainder;

  let a, b, c, d;
  let chunk;

  // Main loop deals with bytes in chunks of 3
  for (let i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];

    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63;               // 63       = 2^6 - 1

    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }

  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];

    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2

    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1

    base64 += encodings[a] + encodings[b] + '==';
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];

    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4

    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1

    base64 += encodings[a] + encodings[b] + encodings[c] + '=';
  }

  return base64;
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}
