import {createUrl} from "./base-url";

export const ReleaseNoteUrls = {
  createReleaseNote: (baseUrl: string) => createUrl(baseUrl, 'releaseNote/createReleaseNote'),
  updateReleaseNote: (baseUrl: string) => createUrl(baseUrl, 'releaseNote/updateReleaseNote'),
  getReleaseAllNote: (baseUrl: string) => createUrl(baseUrl, 'releaseNote/getReleaseAllNote'),
  getReleaseNoteById: (baseUrl: string) => createUrl(baseUrl, 'releaseNote/getReleaseNoteById/:Id'),
  deleteReleaseNote: (baseUrl: string) => createUrl(baseUrl, 'releaseNote/deleteReleaseNote/:Id'),
  getReleaseNoteByVersion: (baseUrl: string) => createUrl(baseUrl, 'releaseNote/getReleaseNoteByVersion?Version=:Version'),
  getCurrentVersionNo: (baseUrl: string) => createUrl(baseUrl, 'getCurrentVersionNo'),
  getReleaseNoteByUserId : (baseUrl: string) => createUrl(baseUrl,'releaseNote/getReleaseNoteByUserId'),
  createReleaseNoteUserMapping : (baseUrl: string) => createUrl(baseUrl,'releaseNote/createReleaseNoteUserMapping'),
  getCurrentVersionByUserId : (baseUrl: string) => createUrl(baseUrl,'releaseNote/getCurrentVersionByUserId'),
}
