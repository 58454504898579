export const environment = {
  production: true,
  apiUrl: 'https://thereadingcenters-api.azurewebsites.net/api/',
  appUrl: 'https://trcc.mypathtolearning.com/',
  environmentName: 'production',
  appInsights: {
    instrumentationKey: '8b666c17-db61-4e68-83b2-bbabae3da169',
  },
  version: "2.1.7"
};
