export function median(data: number[] | Uint8Array) {
  let median = 0,
    numsLen = data.length;
  data?.sort();

  if (
    numsLen % 2 ===
    0 // is even
  ) {
    // average of two middle numbers
    median = (data[numsLen / 2 - 1] + data[numsLen / 2]) / 2;
  } else {
    // is odd
    // middle number only
    median = data[(numsLen - 1) / 2];
  }
  return median;
}

export function mode(numbers: number[] | Uint8Array) {
  let modes = [],
    count: number[] | Uint8Array = [],
    i,
    number,
    maxIndex = 0;
  for (i = 0; i < numbers.length; i += 1) {
    number = numbers[i];
    count[number] = (count[number] || 0) + 1;
    if (count[number] > maxIndex) {
      maxIndex = count[number];
    }
  }

  for (i in count)
    if (count.hasOwnProperty(i)) {
      if (count[i] === maxIndex) {
        modes.push(Number(i));
      }
    }

  return modes;
}
