import { createUrl } from './base-url';

export const DirectoryUrls = {
  getDirectories: (baseUrl: string) => createUrl(baseUrl, 'Directory/getDirectories?DirectoryType=:DirectoryType'),
  createDirectory: (baseUrl: string) => createUrl(baseUrl, 'Directory/createDirectory'),
  updateDirectory: (baseUrl: string) => createUrl(baseUrl, 'Directory/updateDirectory'),
  deleteDirectory: (baseUrl: string) => createUrl(baseUrl, 'Directory/deleteDirectory/:directoryId'),
  moveDirectory: (baseUrl: string) => createUrl(baseUrl, 'Directory/moveDirectory'),
  getDirectoriesWithAssignment: (baseUrl: string) => createUrl(baseUrl, 'Directory/getDirectoriesWithAssignment'),
  getDirectoryWithAssignmentOneLevel: (baseUrl: string) => createUrl(baseUrl, 'Directory/getDirectoryWithAssignmentOneLevelQuery?ParentId=:ParentId'),
};
