import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { AppInsightsLoggingService } from '@mptl/web/services';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Directive({
  selector: '[logClick]',
})
export class LogClickDirective {
  @Input() componentName: string = 'Unknown Component';
  @Input() buttonName: string = 'Unknown Button';
  @Input() methodName: string = 'Unknown Method';

  constructor(
    private element: ElementRef,
    private appInsightLoggingService: AppInsightsLoggingService,
    private route: ActivatedRoute,
    private matDialog: MatDialog
  ) {}

  @HostListener('click')
  onClick(): void {
    // let componentName: string | undefined = this.matDialog.openDialogs.find(s => s.componentInstance[this.methodName] !== undefined)?.componentInstance?.constructor?.name;
    // if (!componentName?.length) {
    //   componentName = this?.route?.routeConfig?.component?.name;
    // }
    this.appInsightLoggingService.logEvent(
      `${this.buttonName} Button From ${this.componentName}  clicked`,
      {
        componentName: this.componentName,
        buttonName: this.buttonName,
        methodName: this.methodName,
      }
    );
  }
}
