import {Inject, Injectable} from '@angular/core';
import {HttpWrapperService} from './httpWrapper';
import {
  BaseResponse,
  DirectoryPaginatedRequest,
  IChangeTestDir,
  IGetPracticeTestWordAttempts,
  IPracticeTestWordAttempts,
  IStudentTestDetails,
  ITest,
  ITestDayConfig,
  ITestDayStudent,
  PaginatedResponse,
  TestIdStudentListRequest,
  TestList,
} from '@mptl/models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {PracticeTestUrls} from './apiUrls/practice-test-urls';

@Injectable()
export class PracticeTestService {
  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any
  ) {
  }

  public getAllTestList(
    request: DirectoryPaginatedRequest
  ): Observable<BaseResponse<PaginatedResponse<TestList[]>, DirectoryPaginatedRequest>> {
    return this.http.post(PracticeTestUrls.getAllTestList(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<PaginatedResponse<TestList[]>,
          DirectoryPaginatedRequest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public createTest(request: ITest): Observable<BaseResponse<string, ITest>> {
    return this.http.post(PracticeTestUrls.createTest(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, ITest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public createTestFromPractice(request: Partial<ITest>): Observable<BaseResponse<string, Partial<ITest>>> {
    return this.http.post(PracticeTestUrls.createTestFromPractice(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, Partial<ITest>> = res;
        data.request = request;
        return data;
      })
    );
  }

  public updateTest(request: ITest): Observable<BaseResponse<string, ITest>> {
    return this.http.post(PracticeTestUrls.updateTest(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, ITest> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getTestById(request: number): Observable<BaseResponse<ITest, number>> {
    return this.http
      .get(
        PracticeTestUrls.getTestById(this.baseUrl).replace(':testId', request?.toString())
      ).pipe(
        map((res) => {
          const data: BaseResponse<ITest, number> = res;
          data.request = request;
          return data;
        })
      );
  }

  public deleteTest(request: number): Observable<BaseResponse<string, number>> {
    return this.http
      .delete(
        PracticeTestUrls.deleteTest(this.baseUrl).replace(
          ':testId',
          request?.toString()
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<string, number> = res;
          data.request = request;
          return data;
        })
      );
  }

  public startStudentTest(
    testId: number
  ): Observable<BaseResponse<IStudentTestDetails, number>> {
    return this.http
      .get(PracticeTestUrls.startPracticeTest(this.baseUrl).replace(':testId', testId?.toString()))
      .pipe(
        map((res: BaseResponse<IStudentTestDetails, number>) => {
          res.request = testId;
          return res;
        })
      );
  }

  public toggleCloseTest(
    testId: number
  ): Observable<BaseResponse<string, number>> {
    return this.http
      .get(PracticeTestUrls.toggleCloseTest(this.baseUrl).replace(':testId', testId?.toString()))
      .pipe(
        map((res: BaseResponse<string, number>) => {
          res.request = testId;
          return res;
        })
      );
  }

  public getTestDayStudents(
    request: TestIdStudentListRequest
  ): Observable<BaseResponse<PaginatedResponse<ITestDayStudent[]>,
    TestIdStudentListRequest>> {
    return this.http
      .post(PracticeTestUrls.getAllStudentAtteptsFromTestId(this.baseUrl), request)
      .pipe(
        map(
          (
            res: BaseResponse<PaginatedResponse<ITestDayStudent[]>,
              TestIdStudentListRequest>
          ) => {
            res.request = request;
            return res;
          }
        )
      );
  }

  public getPracticeTestStudentAttemptsQuery(
    request: IGetPracticeTestWordAttempts
  ): Observable<BaseResponse<IPracticeTestWordAttempts[],
    IGetPracticeTestWordAttempts>> {
    return this.http
      .post(PracticeTestUrls.getPracticeTestStudentAttemptsQuery(this.baseUrl), request)
      .pipe(
        map(
          (
            res: BaseResponse<IPracticeTestWordAttempts[], IGetPracticeTestWordAttempts>) => {
            res.request = request;
            return res;
          }
        )
      );
  }

  public changePracticeTestDirectory(request: IChangeTestDir): Observable<BaseResponse<string, IChangeTestDir>> {
    return this.http.post(PracticeTestUrls.changePracticeTestDirectory(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, IChangeTestDir> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getPracticeTestConfig(request: number): Observable<BaseResponse<Partial<ITestDayConfig>, number>> {
    return this.http.get(PracticeTestUrls.getPracticeTestConfig(this.baseUrl).replace(':testId', request?.toString())).pipe(
      map((res) => {
        const data: BaseResponse<Partial<ITestDayConfig>, number> = res;
        data.request = request;
        return data;
      })
    );
  }

}
