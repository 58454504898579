import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot,} from '@angular/router';
// import { select, Store } from '@ngrx/store';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import {of} from 'rxjs';
import {HttpErrorResponse} from '@angular/common/http';
import {HttpWrapperService} from '../httpWrapper';
import {BaseResponse, IAppState, SetUserAction, UserProfile,} from '@mptl/models';
import {ToastService} from '@mptl/web/ui/toast';
import {AuthService} from '../auth.service';
import {GeneralService} from '../general.service';
import {select, Store} from '@ngrx/store';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private store: Store<IAppState>,
    private authService: AuthService,
    private generalService: GeneralService,
    private http: HttpWrapperService,
    private router: Router,
    public toastr: ToastService
  ) {
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    let user: UserProfile;
    this.store.pipe(select(s => s.sessionUserData), take(1)).subscribe(res => {
      if (res) {
        user = res.user;
        this.generalService.user = res.user;
      }
    });
    if (!user) {
      this.store
        .pipe(
          select((s) => s.root?.user),
          take(1)
        )
        .subscribe((ss) => {
          if (ss) {
            user = ss;
            this.generalService.user = ss;
          }
        });
    }
    if (!this.generalService.user) {
      return this.authService.getUserProfile().pipe(
        map((data: BaseResponse<UserProfile, string>) => {
          if (data.errors.length && data.hasError) {
            data.errors.forEach((err) => {
              this.toastr.error(err.message);
            });
            return undefined;
          } else {
            this.generalService.user = data.data;
            return data.data;
          }
        }),
        switchMap((data: UserProfile | undefined) => {
          if (data) {
            this.store.dispatch(SetUserAction({user: data}));
            if (data?.isStudent && state?.url?.includes('admin-layout')) {
              this.router.navigate(['student-layout']);
            } else if (
              !data.isStudent &&
              state?.url?.includes('student-layout')
            ) {
              this.router.navigate(['admin-layout']);
            }
            return of(true);
          } else {
            this.router.navigate(['/']);
            return of(false);
          }
        }),
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.toastr.error(err.message ?? 'Unauthorized access!');
            // this.store.dispatch(LogoutAction());
          }
          this.router.navigate(['/']);
          return of(false);
        })
      );

    } else {
      if (
        this.generalService.user.isStudent &&
        state?.url?.includes('admin-layout')
      ) {
        this.router.navigate(['student-layout']);
      } else if (
        !this.generalService.user.isStudent &&
        state?.url?.includes('student-layout')
      ) {
        this.router.navigate(['admin-layout']);
      }
      return of(true);
    }
  }
}
