import {ActionReducer, ActionReducerMap, MetaReducer} from '@ngrx/store';
import * as rootReducer from './root.reducer';
import {localStorageSync} from 'ngrx-store-localstorage';
import {IAppState} from '@mptl/models';
import {storageSync} from '@larscom/ngrx-store-storagesync';

declare var window: any;

export const reducers: ActionReducerMap<IAppState> = {
  root: rootReducer.RootReducer,
  canUseStudentApp: rootReducer.canUseStudentAppReducer,
  canRotateDevice: rootReducer.canRotateDeviceReducer,
  canFinalGradeDateRange: rootReducer.canFinalGradeDateRangeReducer,
  canGradingResultsDateRange: rootReducer.canGradingResultsDateRangeReducer,
  sessionUserData: rootReducer.sessionUserData,
  firstTestCreated: rootReducer.firstTestCreated,
  showHelpPopup: rootReducer.showHelpPopup
};

export function sessionStorageSyncReducer(reducer: ActionReducer<IAppState>): ActionReducer<IAppState> {
  const metaReducer = storageSync<IAppState>({
    features: [
      {stateKey: 'sessionUserData'}
    ],
    storage: window.sessionStorage
  });
  return metaReducer(reducer);
}

export function localStorageSyncReducer(
  r: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: [{root: ['token', 'user', 'userLocalConfigs', 'canUseStudentApp', 'studentCode']}, 'canUseStudentApp', 'canRotateDevice', 'canFinalGradeDateRange', 'canGradingResultsDateRange', 'firstTestCreated', 'showHelpPopup'],
    rehydrate: true,
    checkStorageAvailability: true
  })(r);
}

export const metaReducers: Array<MetaReducer<any, any>> = [
  localStorageSyncReducer,
  sessionStorageSyncReducer,
];
