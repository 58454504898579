import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError } from 'rxjs/operators';
import { IAppState, LogoutAction, MessageData } from '@mptl/models';
import { ToastService } from '@mptl/web/ui/toast';
import { Router } from '@angular/router';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(
    public store: Store<IAppState>,
    public toastr: ToastService,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.toastr.error(err.error);
            this.store.dispatch(LogoutAction());
            this.router.navigate(['/']);
          }
          const error: MessageData[] = [];
          error.push({
            type: 'Error',
            message: err.message,
            title: err.status.toString(),
          });
          if (err.status === 400) {
            this.toastr.error(err.error);
          }
          return throwError(error);
        }
        return throwError(err);
      })
    );
  }
}
