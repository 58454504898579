import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RootEffects } from './ngrx/root.effects';
import { metaReducers, reducers } from './ngrx/app.state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreRouterConnectingModule } from '@ngrx/router-store';

const DevImport = [
  StoreDevtoolsModule.instrument({
    maxAge: 25, // Retains last 25 states
    logOnly: true, // Restrict extension to log-only mode
  }),
];

@NgModule({
  imports: [
    CommonModule,
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    ...DevImport,
    EffectsModule.forFeature([RootEffects]),
  ],
})
export class RootStoreModule {}
