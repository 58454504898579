import {Inject, Injectable} from '@angular/core';
import {HttpWrapperService} from './httpWrapper';
import {BaseResponse, Directory, DirectoryType, DirectoryWithFiles, IMoveDirectory} from '@mptl/models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {DirectoryUrls} from './apiUrls/directory-urls';

@Injectable()
export class DirectoryService {
  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any
  ) {
  }

  getDirectories(directoryType: DirectoryType): Observable<BaseResponse<Directory[], DirectoryType>> {
    return this.http.get(DirectoryUrls.getDirectories(this.baseUrl).replace(':DirectoryType', directoryType))
      .pipe(
        map((res) => {
          const data: BaseResponse<Directory[], DirectoryType> = res;
          data.request = directoryType;
          return data;
        })
      );
  }

  public createDirectory(request: Directory): Observable<BaseResponse<number, Directory>> {
    return this.http.post(DirectoryUrls.createDirectory(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<number, Directory> = res;
        data.request = request;
        return data;
      })
    );
  }

  public updateDirectory(request: Directory): Observable<BaseResponse<string, Directory>> {
    return this.http.post(DirectoryUrls.updateDirectory(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, Directory> = res;
        data.request = request;
        return data;
      })
    );
  }

  public moveDirectory(request: IMoveDirectory): Observable<BaseResponse<string, IMoveDirectory>> {
    return this.http.post(DirectoryUrls.moveDirectory(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, IMoveDirectory> = res;
        data.request = request;
        return data;
      })
    );
  }

  public deleteDirectory(request: number): Observable<BaseResponse<string, number>> {
    return this.http.delete(DirectoryUrls.deleteDirectory(this.baseUrl).replace(':directoryId', request?.toString()), request).pipe(
      map((res) => {
        const data: BaseResponse<string, number> = res;
        data.request = request;
        return data;
      })
    );
  }

  public getDirectoriesWithAssignment(): Observable<BaseResponse<DirectoryWithFiles[], undefined>> {
    return this.http.get(DirectoryUrls.getDirectoriesWithAssignment(this.baseUrl))
      .pipe(
        map((res) => {
          const data: BaseResponse<DirectoryWithFiles[], undefined> = res;
          return data;
        })
      );
  }


  public getDirectoryWithAssignmentOneLevel(parentId: number): Observable<BaseResponse<DirectoryWithFiles, number>> {
    return this.http.get(DirectoryUrls.getDirectoryWithAssignmentOneLevel(this.baseUrl).replace(':ParentId', parentId?.toString()))
      .pipe(
        map((res) => {
          const data: BaseResponse<DirectoryWithFiles, number> = res;
          data.request = parentId;
          return data;
        })
      );
  }

}
