import {ModuleWithProviders, NgModule} from '@angular/core';
import {GeneralService} from './services/general.service';
import {AuthService} from './services/auth.service';
import {AppInterceptor} from './services/app.interceptor';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonModule} from '@angular/common';
import {HttpWrapperService} from './services/httpWrapper';
import {AuthGuard} from './services/guards/authGuard';
import {NavigationService} from './services/navigation.service';
import {FileService} from './services/file.service';
import {StudentService} from './services/student.service';
import {GroupService} from './services/group.service';
import {AssignmentService} from './services/assignment.service';
import {TestService} from './services/test.service';
import {AppInsightsLoggingService} from './services/app-insights-logging.service';
import {NgDialogAnimationService} from './services/dialog.service';
import {MatDialogModule} from '@angular/material/dialog';
import {DirectoryService} from './services/directory.service';
import {PracticeTestService} from "./services/practice-test.service";
import {MarketPlaceService} from "./services/market-place.service";
import {GlobleSettingsService} from "./services/globle-settings.service";
import {ReleaseNoteService} from "./services/release-note-service";
import {TagService} from "./services/tag.service";
import {AttemptDbService} from "./services/attempt-db.service";
import { ReportService } from './services/report.services';
import {ChatService} from "./services/chat.service";
import {SignalRClientService} from "./services/signalR-client.service";

@NgModule({
  imports: [CommonModule, HttpClientModule, MatDialogModule],
  exports: [],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true},
  ],
})
export class ServicesModule {
  static forRoot(): ModuleWithProviders<ServicesModule> {
    return {
      ngModule: ServicesModule,
      providers: [
        GeneralService,
        HttpWrapperService,
        NavigationService,
        AuthService,
        AuthGuard,
        FileService,
        StudentService,
        GroupService,
        AssignmentService,
        TestService,
        DirectoryService,
        NgDialogAnimationService,
        PracticeTestService,
        AppInsightsLoggingService,
        MarketPlaceService,
        GlobleSettingsService,
        ReleaseNoteService,
        AttemptDbService,
        TagService,
        ReportService,
        ChatService,
        SignalRClientService
      ],
    };
  }
}
