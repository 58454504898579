import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'roundNumberToTwoDigit' })
export class RoundNumberToTwoDigitPipe implements PipeTransform {
  transform(num: number): number {
    if (num) {
      return num ? +(Math.round(+(num + 'e+2')) + 'e-2') : 0.0;
    } else {
      return 0.0;
    }
  }
}
