import {Inject, Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {ComponentStore} from "@ngrx/component-store";
import {HubConnection, HubConnectionState, LogLevel, RetryContext} from "@microsoft/signalr";
import {ChangeChatStatus, MessageReceived, NewChatArrived, Signals} from "@mptl/models";

declare let signalR: any;

export interface ISignalRClientState {
  connectionInProgress: boolean;
  connectionError: null | string;
  messageReceived: MessageReceived;
  changeChatStatus: ChangeChatStatus;
  newChatArrived: NewChatArrived;
}

@Injectable()
export class SignalRClientService extends ComponentStore<ISignalRClientState> {
  connection: HubConnection | null = null;
  connectionInProgress$: Observable<boolean> = this.select(s => s.connectionInProgress);
  connectionError$: Observable<null | string> = this.select(s => s.connectionError);
  messageReceived$: Observable<MessageReceived> = this.select(s => s.messageReceived);
  changeChatStatus$: Observable<ChangeChatStatus> = this.select(s => s.changeChatStatus);
  newChatArrived$: Observable<NewChatArrived> = this.select(s => s.newChatArrived);

  constructor(@Inject('BASE_URL') private baseUrl: string,  @Inject('ENVIRONMENT_NAME')
  private environmentName: 'local' | 'development' | 'production',) {
    super({
      connectionInProgress: false,
      connectionError: null,
      messageReceived: null,
      changeChatStatus: null,
      newChatArrived: null
    });
  }


  initConnection(userId: string) {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(this.baseUrl.replace('/api', '/signals') + `?userId=${userId?.toString()}`)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds(retryContext: RetryContext): number | null {
          if (retryContext.previousRetryCount < 10) {
            return 1000
          } else if (retryContext.previousRetryCount >= 10 && retryContext.previousRetryCount < 20) {
            return 2000
          } else if (retryContext.previousRetryCount >= 20 && retryContext.previousRetryCount < 100) {
            return 10000
          } else {
            return null;
          }
        }
      })
      .configureLogging(this.environmentName === 'local' ? LogLevel.Debug : LogLevel.Error)
      .build();
  }

  get connectionState(): HubConnectionState | string {
    return this.connection?.state ?? '';
  }

  startConnection(userId: string): Promise<boolean> {
    this.setState((state) => ({
      ...state,
      connectionInProgress: true,
      connectionError: null
    }));
    try {
      return this.stopConnection().then(() => {
        if (!this.connection) {
          this.initConnection(userId);
        }
        return this.connection?.start().then(s => {
          this.registerListener();
          this.setState((state) => ({
            ...state,
            connectionInProgress: false,
            connectionError: null
          }));
          return this.connection?.state === HubConnectionState.Connected;
        }).catch(err => {
          console.log(err)
          this.setState((state) => ({
            ...state,
            connectionInProgress: false,
            connectionError: 'Connection Failed!'
          }));
          return err
        })
      });
    } catch (e) {
      return new Promise<boolean>((resolve, reject) => {
        console.log(e)
        this.setState((state) => ({
          ...state,
          connectionInProgress: false,
          connectionError: 'Connection Failed!'
        }));
        reject(e)
      });
    }

  }

  registerListener() {
    this.connection?.onclose(error => {
      console.log("close", error);
    })
    this.connection?.onreconnecting(error => {
      console.log("reconnecting", error)
    })
    this.connection?.onreconnected(connectionId => {
      console.log("reconnected", connectionId);
    })
    this.connection?.on(Signals.MessageReceived, (event: string) => {
      this.setState((state) => ({
        ...state,
        messageReceived: JSON.parse(event)
      }));
    })
    this.connection?.on(Signals.ChangeChatStatus, (event: string) => {
      this.setState((state) => ({
        ...state,
        changeChatStatus: JSON.parse(event)
      }));
    })
    this.connection?.on(Signals.NewChatArrived, (event: string) => {
      this.setState((state) => ({
        ...state,
        newChatArrived: JSON.parse(event)
      }));
    })
  }

  unregisterListener() {
    this.connection?.off('connected');
    this.connection?.off('close')
    this.connection?.off('reconnecting')
    this.connection?.off('reconnected')
    this.connection?.off(Signals.MessageReceived)
    this.connection?.off(Signals.ChangeChatStatus)
    this.connection?.off(Signals.NewChatArrived)
    this.connection?.off('pong')
  }

  readonly resetMessageReceived = this.updater((state) => ({
    ...state,
    messageReceived: null
  }))

  readonly resetChangeChatStatus = this.updater((state) => ({
    ...state,
    changeChatStatus: null
  }))

  readonly resetNewChatArrived = this.updater((state) => ({
    ...state,
    newChatArrived: null
  }))

  ping() {
    this.connection?.invoke('ping', {text: "hello"}).then()
  }

  stopConnection(): Promise<void> {
    if (this.connection) {
      return this.connection?.stop().then(s => {
        if (this.connection?.state === HubConnectionState.Disconnected) {
          this.unregisterListener();
          this.connection = null;
        }
      });
    } else {
      return new Promise<void>((resolve, reject) => resolve())
    }
  }

}
