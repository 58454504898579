import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { catchError, take } from 'rxjs/operators';
import { IAppState, LogoutAction, MessageData } from '@mptl/models';
import { ToastService } from '@mptl/web/ui/toast';
import { Router } from '@angular/router';
import { GeneralService } from '@mptl/web/services';
import { AppInstallNowComponent } from '@mptl/web/ui/app-install-now';
import { MatBottomSheet } from '@angular/material/bottom-sheet';

declare var window: any;
declare var localStorage: any;
declare var alert: (message?: string) => void;


@Injectable()
export class AppInterceptor implements HttpInterceptor {

  private isPopupShown = false;
  constructor(
    public store: Store<IAppState>,
    public toastr: ToastService,
    private router: Router,
    private generalService: GeneralService,
    private _bottomSheet: MatBottomSheet,
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const version =  localStorage.getItem('apiVersion') || '1.0.0';
    this.generalService.version.next(version);
    const clonedRequest = request.clone({
      setHeaders: { 'App-Version': version }
    });
    return next.handle(clonedRequest).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.toastr.error(err.error);
            this.store.dispatch(LogoutAction());
            this.router.navigate(['/']);
          }
          const error: MessageData[] = [];
          error.push({
            type: 'Error',
            message: err.message,
            title: err.status.toString(),
          });
          if (err.status === 400) {
            this.toastr.error(err.error);
          }
          if (err.status === 409) {
            this.handleVersionMismatch(err);
          }
          return throwError(error);
        }
        return throwError(err);
      })
    );
  }



  private handleVersionMismatch(error: HttpErrorResponse) {
    if (!this.isPopupShown) {
      this.isPopupShown = true;
      const serverVersion = error.error?.version;
      if (serverVersion) {
        if (this.generalService.isStandalone) {
          this._bottomSheet
            .open(AppInstallNowComponent, {
              disableClose: true,
              hasBackdrop: true,
              data: {isUpdateMode: true}
            })
            .afterDismissed()
            .pipe(take(1))
            .subscribe(() => {
              this.setVersionAndReload(serverVersion);
            });
        } else {
          alert('A new version has been detected and will be updated.');
          this.setVersionAndReload(serverVersion)
        }
      }
    }
  }


  private setVersionAndReload(serverVersion: string){
    localStorage.setItem('apiVersion', serverVersion);
    this.generalService.version.next(serverVersion);
    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => caches.delete(cacheName));
      });
    }
    (window.location as any).reload(true);
  }
}
