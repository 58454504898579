import { Directive, HostListener, Input } from '@angular/core';
import { NavigationService } from '@mptl/web/services';

@Directive({
  selector: '[backButton]',
})
export class BackButtonDirective {
  @Input() fallBackPath: string = '';
  constructor(private navigation: NavigationService) {}

  @HostListener('click')
  onClick(): void {
    this.navigation.back(this.fallBackPath);
  }
}
