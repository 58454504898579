import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFromDecimal',
  pure: true
})
export class TimeFromDecimalPipe implements PipeTransform {
  transform(value: number, ...args: string[]): any {
    if (value && !isNaN(value)) {
      const totalSecs = value * 60;
      if (totalSecs >= 3600) {
        const totalHr = Math.floor(totalSecs / 3600);
        const totalMin = Math.floor((totalSecs - (totalHr * 3600)) / 60);
        const offsetSec = Math.floor(totalSecs - (totalHr * 3600) - (totalMin * 60));
        return (
          +
            'h ' + totalHr?.toString() +
          totalMin?.toString() +
          'm ' +
          offsetSec?.toString() +
          's'
        );
      } else if (totalSecs >= 60) {
        const totalMin = Math.floor(totalSecs / 60);
        const offsetSec = Math.floor(totalSecs - (totalMin * 60));
        return (
          totalMin?.toString() +
          'm ' +
          offsetSec?.toString() +
          's'
        );
      }
      return (
        '0m ' +
        Math.floor(totalSecs) + 's'
      );
    } else {
      return '';
    }
  }
}
