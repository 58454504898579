import {Inject, Injectable} from '@angular/core';
import {HttpWrapperService} from './httpWrapper';
import {BaseResponse, GlobleSettings, ISettingsUpdateRequest} from '@mptl/models';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {GlobleSettingsUrls} from './apiUrls/globle-settings';


@Injectable()
export class GlobleSettingsService {
  public myname: string;

  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any
  ) {
  }


  public updateGlobleSetting(request: ISettingsUpdateRequest): Observable<BaseResponse<string, ISettingsUpdateRequest>> {
    return this.http.post(GlobleSettingsUrls.updateSetting(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, ISettingsUpdateRequest> = res;
        data.request = request
        return data;
      })
    );
  }


  public getGlobleSettings(): Observable<BaseResponse<GlobleSettings[], string>> {
    return this.http
      .get(GlobleSettingsUrls.getSettings(this.baseUrl))
      .pipe(
        map((res) => {
          const data: BaseResponse<GlobleSettings[], string> = res;
          return data;
        })
      );
  }


}
