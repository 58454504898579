import {Component, OnInit} from '@angular/core';

@Component({
  selector: ' app-view-order',
  templateUrl: './no-internet-connection-component.html',
  styleUrls: ['./no-internet-connection-component.scss'],
})
export class NoInternetConnectionComponent
  implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
