import {Inject, Injectable} from '@angular/core';
import {HttpWrapperService} from './httpWrapper';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import { BaseResponse, GetUserEventReportData, GetUserEventReportReq, SendReportReq } from '@mptl/models';
import { ReportUrls } from './apiUrls/report-urls';
import { AssignmentUrls } from './apiUrls/assignment-urls';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class ReportService {
  constructor(
    private http: HttpWrapperService,
    private httpClient: HttpClient,
    @Inject('BASE_URL') private baseUrl: any
  ) {
  }

  public getUserEventReport(
    request: GetUserEventReportReq
  ): Observable<BaseResponse<GetUserEventReportData, GetUserEventReportReq>> {
    return this.http.post(ReportUrls.getUserEventReport(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<GetUserEventReportData, GetUserEventReportReq> = res;
        data.request = request;
        return data;
      })
    );
  }

  public exportUserEventReport(request: GetUserEventReportReq): Observable<Blob> {
    return this.httpClient
      .post(ReportUrls.exportUserEventReport(this.baseUrl), request, {
        headers: {
          Authorization: this.http.getCurrentToken(),
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        responseType: 'blob'
      })
      .pipe(
        map((res) => {
          const data: Blob = res;
          return data;
        })
      );
  }
  public sendEmailForReport(
    request: SendReportReq
  ): Observable<BaseResponse<string, SendReportReq>> {
    return this.http.post(ReportUrls.sendEmailForReport(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, SendReportReq> = res;
        data.request = request;
        return data;
      })
    );
  }
}
