import {BaseFilterRequest} from '../base/baseResponse';

export class ChatList {
  id: string;
  name: string;
  logoURL: string;
  type: string;
  status: string;
  unreadMessageCount: number;
  receivers: Receivers[];
  lastMessage: ChatDetail;
}

export class ChatDetail {
  id: string;
  message: string;
  conversationName: string;
  conversationIconUrl: string;
  attachments: Attachments[];
  senderId: number;
  senderName: string;
  date: Date;
  receivers: Receivers[];
  isRead: boolean;
  isSender?: boolean;
}

export class Attachments {
  fileUrl: string;
  fileType: string;
}

export class Receivers {
  id: number;
  name: string;
}


export enum FileType {
  Image = 'Image',
  Video = 'Video',
  Pdf = 'Pdf'
}


export enum MessageType {
  MessageOnly = 'MessageOnly',
  MessageAndFile = 'MessageAndFile',
  Date = 'Date',
  FileOnly = 'FileOnly',
  UnRead = 'UnRead'
}

export class MessageDetail {
  id: string;
  message: string;
  type: MessageType;
  isSender: boolean;
  date: Date;
  conversationIconUrl: string;
  isRead: boolean;
  attachments: Attachments[];
}


export class SendChat {
  userId: number;
  chatId: string;
  message: string;
  attachmentKeys: string[];
}


export class StartNewChat {
  fromUserId: number;
  toUserId: number;
}

export class GetAllChatsByUserId extends BaseFilterRequest {
  userId: number;
}

export class GetChatMessage extends BaseFilterRequest {
  chatId: string;
}


export class ChatDetailHeader {
  id: string;
  name: string;
  logoURL: string;
  type: string;
  unreadMessageCount: number;
  status: string;
  lastMessage: ChatDetail
}


export class MessageReceived {
  chatId: string;
  message: ChatDetail
}

export class ChangeChatStatus {
  chatId: string;
  status: string;
}

export class NewChatArrived {
  chatId: string;
  chat: ChatList;
}
export class ChatAdmin{
  id: number;
  name: string;
  isChatAdmin: boolean;
}

