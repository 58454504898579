import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {pipe} from 'rxjs';
import {filter, take, tap} from 'rxjs/operators';
import {AppInsightsLoggingService, GeneralService} from '@mptl/web/services';
import {Router} from '@angular/router';
import {ROUTER_REQUEST, RouterRequestAction} from '@ngrx/router-store';
import {
  AppInsightsEvents,
  IAppState,
  LoginCompleteAction,
  LogoutAction,
  SelectCanUseStudentApp,
  SetCanUseStudentApp,
  SetFinalGradeDateRange,
  SetGradingResultsDateRange,
  SetSessionUserData
} from '@mptl/models';
import {select, Store} from '@ngrx/store';

@Injectable()
export class RootEffects {
  constructor(
    private actions$: Actions,
    private generalService: GeneralService,
    private rootStore: Store<IAppState>,
    private appInsightsLoggingService: AppInsightsLoggingService,
    private router: Router
  ) {
  }

  logoutUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LogoutAction),
        pipe(
          tap((p) => {
            this.appInsightsLoggingService.logEvent(
              AppInsightsEvents.USER_LOG_OUT,
              this.generalService.user
            );
            this.generalService.user = undefined;
            this.appInsightsLoggingService.removeLoggedInUser();
            this.rootStore.dispatch(SetFinalGradeDateRange({state: null}))
            this.rootStore.dispatch(SetGradingResultsDateRange({state: null}))
            // this.router.navigate(['public-home-layout']);
          })
        )
      ),
    {
      dispatch: false
    }
  );

  loginComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(LoginCompleteAction),
        tap((res) => {
          let localTouchAble;
          this.rootStore.pipe(select(SelectCanUseStudentApp), take(1)).subscribe(res => localTouchAble = res);
          if (localTouchAble === undefined) {
            this.rootStore.dispatch(SetCanUseStudentApp({state: this.generalService.isTouchEnabled}));
          }
          this.appInsightsLoggingService.addLoggedInUser(
            res?.response?.user?.firstName +
            ' ' +
            res?.response?.user?.lastName,
            res?.response?.user?.id?.toString()
          );
          this.appInsightsLoggingService.logEvent(
            AppInsightsEvents.USER_LOGGED_IN,
            res.response
          );
        })
      ),
    {dispatch: false}
  );


  sessionLoginComplete$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SetSessionUserData),
        tap((res) => {
          let localTouchAble;
          this.rootStore.pipe(select(SelectCanUseStudentApp), take(1)).subscribe(res => localTouchAble = res);
          if (localTouchAble === undefined) {
            this.rootStore.dispatch(SetCanUseStudentApp({state: this.generalService.isTouchEnabled}));
          }
          this.appInsightsLoggingService.logEvent(
            'LOGIN_AS_USER',
            res.response
          );
        })
      ),
    {dispatch: false}
  );

  studentRoutEffect = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ROUTER_REQUEST),
        filter(
          (route: RouterRequestAction) =>
            route?.payload?.routerState.url.includes('student-layout') ||
            route?.payload?.event.url.includes('student-layout')
        ),
        tap((res) => {
          if (
            !res.payload.routerState.url.includes('test-start') && !res.payload.routerState.url.includes('practice-start') &&
            (res.payload.event.url.includes('test-start') || res.payload.event.url.includes('practice-start'))
          ) {
            this.generalService.studentHeaderStatus = 'HIDE';
          }
          if (!res.payload.event.url.includes('test-start') && !res.payload.event.url.includes('practice-start')) {
            this.generalService.studentHeaderStatus = 'SHOW';
          }
        })
      ),
    {
      dispatch: false
    }
  );
}
