import {AssignmentSubTypes, AssignmentTypes} from "../assignment/assignment";

export class Directory {
  public name: string = 'New Folder';
  public id: number = 0;
  public children: Directory[] = [];
  public parentId: number;
  public directoryType?: DirectoryType;
  public confirmed?: boolean = false;
  public expanded: boolean = false;

  static findDirectoryById(directories: Directory[], id: number): Directory | null {
    let directory = directories.find(s => s.id === id);
    if (directory) {
      return directory;
    } else {
      let directory: Directory;
      for (let i = 0; i < directories.length; i++) {
        let currentDirectory = directories[i];
        if (currentDirectory.children?.length) {
          directory = this.findDirectoryById(currentDirectory.children, id);
        }
        if (directory) {
          break;
        }
      }
      return directory;
    }
  }

  static findDirectory(directories: Directory[], FindFunction: (directory: Directory) => boolean): Directory | null {
    let directory = directories.find(FindFunction);
    if (directory) {
      return directory;
    } else {
      let directory: Directory;
      for (let i = 0; i < directories.length; i++) {
        let currentDirectory = directories[i];
        if (currentDirectory.children?.length) {
          directory = this.findDirectory(currentDirectory.children, FindFunction);
        }
        if (directory) {
          break;
        }
      }
      return directory;
    }
  }

  static searchInDirectoryById = (directory: Directory, id: number): Directory => {
    if (directory.id == id) {
      return directory;
    } else if (directory.children != null) {
      let i;
      let result = null;
      for (i = 0; result == null && i < directory.children.length; i++) {
        result = this.searchInDirectoryById(directory.children[i], id);
      }
      return result;
    } else {
      return null;
    }
  };

  static addDirectoryToParentId(directories: Directory[], parentId: number, directory: Directory): Directory[] {
    const parentNode = this.findDirectoryById(directories, parentId);
    if (parentNode) {
      if (!parentNode.children) {
        parentNode.children = [];
      }
      parentNode.children.push(directory);
    }
    return directories;
  }

  static updateDirectoryBytId(directories: Directory[], id: number, directory: Partial<Directory>): Directory[] {
    let oldDirectory = this.findDirectoryById(directories, id);
    if (directory) {
      Object.keys(directory).forEach(key => {
        if (oldDirectory) {
          oldDirectory[key] = directory[key];
        }
      });
    }
    return directories;
  }

  static updateAllDirectories(directories: Directory[], changes: Partial<Directory>): Directory[] {
    for (let i = 0; i < directories?.length; i++) {
      let currentDirectory = directories[i];
      Object.keys(changes).forEach(changeKey => {
        currentDirectory[changeKey] = changes[changeKey];
      });
      if (currentDirectory.children?.length) {
        currentDirectory.children = this.updateAllDirectories(currentDirectory.children, changes);
      }
    }
    return directories;
  }

  static removeDirectoryBytId(directories: Directory[], id: number): Directory[] {
    let directory = this.findDirectoryById(directories, id);
    if (directory.parentId) {
      let parentNode = this.findDirectoryById(directories, directory.parentId);
      if (parentNode) {
        parentNode.children = parentNode.children.filter(s => s.id !== id);
      }
    }
    return directories;
  }

  static filterDirectories(directories: Directory[], filterFunction: (directory: Directory) => boolean): Directory[] {
    const newDirectories = [];
    for (let i = 0; i < directories?.length; i++) {
      let currentDirectory = directories[i];
      if (filterFunction(currentDirectory)) {
        newDirectories.push(currentDirectory);
      } else {
        continue;
      }
      if (currentDirectory.children?.length) {
        currentDirectory.children = this.filterDirectories(currentDirectory.children, filterFunction);
      }
    }
    return newDirectories;
  }

}


export class DirectoryWithFiles {
  public name: string = 'New Folder';
  public id: string = '';
  public children?: DirectoryWithFiles[] = [];
  public parentId: string;
  public directoryType?: DirectoryType;
  public confirmed?: boolean = false;
  public expanded: boolean = false;
  public isExpandable: boolean = false;
  public hasChildren: boolean = false;
  public type?: AssignmentTypes;
  public subType: AssignmentSubTypes;
  public totalRecordingAvailable: number;
  public totalWord: number;

  static findDirectoryById(directories: DirectoryWithFiles[], id: string): DirectoryWithFiles | null {
    let directory = directories.find(s => s.id === id);
    if (directory) {
      return directory;
    } else {
      let directory: DirectoryWithFiles;
      for (let i = 0; i < directories.length; i++) {
        let currentDirectory = directories[i];
        if (currentDirectory.children?.length) {
          directory = this.findDirectoryById(currentDirectory.children, id);
        }
        if (directory) {
          break;
        }
      }
      return directory;
    }
  }

  static findDirectory(directories: DirectoryWithFiles[], FindFunction: (directory: DirectoryWithFiles) => boolean): DirectoryWithFiles | null {
    let directory = directories.find(FindFunction);
    if (directory) {
      return directory;
    } else {
      let directory: DirectoryWithFiles;
      for (let i = 0; i < directories.length; i++) {
        let currentDirectory = directories[i];
        if (currentDirectory.children?.length) {
          directory = this.findDirectory(currentDirectory.children, FindFunction);
        }
        if (directory) {
          break;
        }
      }
      return directory;
    }
  }

  static searchInDirectoryById = (directory: DirectoryWithFiles, id: string): DirectoryWithFiles => {
    if (directory.id == id) {
      return directory;
    } else if (directory.children != null) {
      let i;
      let result = null;
      for (i = 0; result == null && i < directory.children.length; i++) {
        result = this.searchInDirectoryById(directory.children[i], id);
      }
      return result;
    } else {
      return null;
    }
  };

  static addDirectoryToParentId(directories: DirectoryWithFiles[], parentId: string, directory: DirectoryWithFiles): DirectoryWithFiles[] {
    const parentNode = this.findDirectoryById(directories, parentId);
    if (parentNode) {
      if (!parentNode.children) {
        parentNode.children = [];
      }
      parentNode.children.push(directory);
    }
    return directories;
  }

  static updateDirectoryBytId(directories: DirectoryWithFiles[], id: string, directory: Partial<DirectoryWithFiles>): DirectoryWithFiles[] {
    let oldDirectory = this.findDirectoryById(directories, id);
    if (directory) {
      Object.keys(directory).forEach(key => {
        if (oldDirectory) {
          oldDirectory[key] = directory[key];
        }
      });
    }
    return directories;
  }

  static updateAllDirectories(directories: DirectoryWithFiles[], changes: Partial<DirectoryWithFiles>): DirectoryWithFiles[] {
    for (let i = 0; i < directories?.length; i++) {
      let currentDirectory = directories[i];
      Object.keys(changes).forEach(changeKey => {
        currentDirectory[changeKey] = changes[changeKey];
      });
      if (currentDirectory.children?.length) {
        currentDirectory.children = this.updateAllDirectories(currentDirectory.children, changes);
      }
    }
    return directories;
  }

  static removeDirectoryBytId(directories: DirectoryWithFiles[], id: string): DirectoryWithFiles[] {
    let directory = this.findDirectoryById(directories, id);
    if (directory.parentId) {
      let parentNode = this.findDirectoryById(directories, directory.parentId);
      if (parentNode) {
        parentNode.children = parentNode.children.filter(s => s.id !== id);
      }
    }
    return directories;
  }

  static filterDirectories(directories: DirectoryWithFiles[], filterFunction: (directory: DirectoryWithFiles) => boolean): DirectoryWithFiles[] {
    const newDirectories = [];
    for (let i = 0; i < directories?.length; i++) {
      let currentDirectory = directories[i];
      if (filterFunction(currentDirectory)) {
        newDirectories.push(currentDirectory);
      } else {
        continue;
      }
      if (currentDirectory.children?.length) {
        currentDirectory.children = this.filterDirectories(currentDirectory.children, filterFunction);
      }
    }
    return newDirectories;
  }


  static mapDirectories(directories: DirectoryWithFiles[], mapFunction: (directory: DirectoryWithFiles) => DirectoryWithFiles): DirectoryWithFiles[] {
    const newDirectories = [];
    for (let i = 0; i < directories?.length; i++) {
      let currentDirectory = directories[i];
      if (currentDirectory.children?.length) {
        currentDirectory.children = this.mapDirectories(currentDirectory.children, mapFunction);
      }
      currentDirectory = mapFunction(currentDirectory);
      newDirectories.push(currentDirectory);
    }
    return newDirectories;
  }

}


export interface IMoveDirectory {
  id: number;
  moveToDirectoryId: number;
}


export class DirectoryNode {
  expandable: boolean = true;
  data: Directory;
  level: number;
  isExpanded?: boolean;
}

export interface IDirectoryType {
  name: string;
  value: DirectoryType;
}

export enum DirectoryType {
  AssignmentDirectory = 'AssignmentDirectory',
  TestDirectory = 'TestDirectory',
  PracticeTestDirectory = 'PracticeTestDirectory'
}
