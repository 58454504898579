import {BaseFilterRequest} from '../base/baseResponse';

export interface PublishMarketAssignment {
  assignmentId: number;
  assignmentName: string;
  grade: string;
  description: string;
}


export interface IMarketPlaceAssignment {
  id: number;
  assignmentName: string;
  assignmentType: string;
  description: string;
  grade: string;
  isPublished: boolean;
  teacherName: string;
  downloadInProgress: boolean;
  noOfWords: number;
  panelOpenState?: boolean;
  tagId: number;
  tagName: string;
}


export interface IMarketPlaceAssignmentWithWords {
  id: number;
  assignmentName: string;
  assignmentType: string;
  isViewOnly: boolean;
  assignmentWords: IMarketPlaceAssignmentWord[];
}

export interface IMarketPlaceAssignmentWord {
  id: number;
  word: string;
  answer: string;
  sequence: number;
  audioKey: string;
  imageKey: string;
  imageUrl: string;
  type: string;
  audioRecordingURL: string;
}


export interface IDownLoadFromMarketPlaceRequest {
  marketPlaceAssignmentId: number,
  directoryId: number,
  assignmentName: string;
}

export class MarketPlaceFilterRequest extends BaseFilterRequest {
  onlyMyLibrary: boolean = true;
  filterByStatus: string;
}

export class UpdateMarketPlaceRequest {
  assignmentId: number;
  name: string;
  description: string;
  tagId: number;
}
