import {Inject, Injectable} from "@angular/core";
import { Observable} from "rxjs";
import {
  BaseResponse, ChatAdmin,
  ChatDetail,
  ChatDetailHeader,
  ChatList,
  GetAllChatsByUserId,
  GetChatMessage,
  PaginatedResponse,
  SendChat,
  StartNewChat
} from '@mptl/models';
import {HttpWrapperService} from "./httpWrapper";
import {ChatUrls} from "./apiUrls/chat-urls";
import {map} from 'rxjs/operators';

@Injectable()
export class ChatService {
  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any
  ) {
  }

  public sendMessageService(request: SendChat): Observable<BaseResponse<ChatDetail, SendChat>> {
    return this.http.post(ChatUrls.sendMessage(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<ChatDetail, SendChat> = res;
        return data;
      })
    );
  }

  public startNewChatService(request: StartNewChat): Observable<BaseResponse<string, StartNewChat>> {
    return this.http.post(ChatUrls.startNewChat(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<string, StartNewChat> = res;
        return data;
      })
    );
  }

  public getAllChatsByUserIdService(request: GetAllChatsByUserId): Observable<BaseResponse<PaginatedResponse<ChatList[]>, GetAllChatsByUserId>> {
    return this.http.post(ChatUrls.getAllChatsByUserId(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<PaginatedResponse<ChatList[]>, GetAllChatsByUserId> = res;
        return data;
      })
    );
  }

  public getChatMessageService(request: GetChatMessage): Observable<BaseResponse<PaginatedResponse<ChatDetail[]>, GetChatMessage>> {
    return this.http.post(ChatUrls.getChatMessage(this.baseUrl), request).pipe(
      map((res) => {
        const data: BaseResponse<PaginatedResponse<ChatDetail[]>, GetChatMessage> = res;
        return data;
      })
    );
  }

  public getChatDetailByIdService(request: string): Observable<BaseResponse<ChatDetailHeader, string>> {
    return this.http.get(ChatUrls.getChatDetailById(this.baseUrl).replace(':chatId', request)).pipe(
      map((res) => {
        const data: BaseResponse<ChatDetailHeader, string> = res;
        return data;
      })
    );
  }

  public markAsReadMessageByChatIdService(request: string): Observable<BaseResponse<string, string>> {
    return this.http.get(ChatUrls.markAsReadMessageByChatId(this.baseUrl).replace(':chatId', request)).pipe(
      map((res) => {
        const data: BaseResponse<string, string> = res;
        return data;
      })
    );
  }

  public getChatAdmin(): Observable<BaseResponse<ChatAdmin, string>> {
    return this.http.get(ChatUrls.getChatAdmin(this.baseUrl)).pipe(
      map((res) => {
        const data: BaseResponse<ChatAdmin, string> = res;
        return data;
      })
    );
  }
}
