import { Inject, Injectable } from '@angular/core';
import { HttpWrapperService } from './httpWrapper';
import {
  AssignmentTypes, BaseFileResponse,
  BaseResponse,
  DemoStudentLoginReq,
  IStudentTest,
  LoginResponse, MoveStudentToGroup,
  RemoveStudent,
  StudentListDropDownModel,
  StudentLogin,
  StudentModel,
  TodayTests
} from '@mptl/models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StudentUrls } from './apiUrls/student-urls';
import { HttpClient, HttpEvent, HttpProgressEvent, HttpSentEvent } from '@angular/common/http';
import * as moment from 'moment';
import { TestUrls } from './apiUrls/test-urls';

@Injectable()
export class StudentService {
  constructor(
    private http: HttpWrapperService,
    @Inject('BASE_URL') private baseUrl: any,
    private httpClient: HttpClient
  ) {
  }

  public createStudent(
    request: StudentModel
  ): Observable<BaseResponse<string, StudentModel>> {
    return this.http
      .post(StudentUrls.createStudent(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, StudentModel> = res;
          return data;
        })
      );
  }

  public updateStudent(
    request: StudentModel
  ): Observable<BaseResponse<string, StudentModel>> {
    return this.http
      .post(StudentUrls.updateStudent(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, StudentModel> = res;
          return data;
        })
      );
  }

  public getStudentById(
    id: string
  ): Observable<BaseResponse<StudentModel, string>> {
    return this.http
      .get(StudentUrls.getStudentById(this.baseUrl).replace(':studentId', id))
      .pipe(
        map((res) => {
          const data: BaseResponse<StudentModel, string> = res;
          return data;
        })
      );
  }

  public removeStudent(request: RemoveStudent): Observable<BaseResponse<string, RemoveStudent>> {
    return this.http
      .post(StudentUrls.removeStudent(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, RemoveStudent> = res;
          return data;
        })
      );
  }

  public removeAllDemoStudent(): Observable<BaseResponse<string, string>> {
    return this.http
      .get(StudentUrls.removeAllDemoStudent(this.baseUrl))
      .pipe(
        map((res) => {
          const data: BaseResponse<string, string> = res;
          return data;
        })
      );
  }

  public getStudentList(
    request: string,
    showDemoStudent: boolean = false
  ): Observable<BaseResponse<StudentModel[], string>> {
    return this.http
      .get(StudentUrls.getStudentList(this.baseUrl).replace(':search', request).replace(':showDemoStudent', showDemoStudent ? 'true' : 'false'))
      .pipe(
        map((res) => {
          const data: BaseResponse<StudentModel[], string> = res;
          return data;
        })
      );
  }

  public getStudentListForDropdown(
    request: string
  ): Observable<BaseResponse<StudentListDropDownModel[], string>> {
    return this.http
      .get(
        StudentUrls.getStudentListForDropdown(this.baseUrl).replace(
          ':search',
          request
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<StudentListDropDownModel[], string> = res;
          return data;
        })
      );
  }

  public getStudentListForDropdownWithoutDemo(
    request: string
  ): Observable<BaseResponse<StudentListDropDownModel[], string>> {
    return this.http
      .get(
        StudentUrls.getStudentListForDropdownWithoutDemo(this.baseUrl).replace(
          ':search',
          request
        )
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<StudentListDropDownModel[], string> = res;
          return data;
        })
      );
  }

  public getAvailableStudentList(request: {
    groupId: number;
    search: string;
    type: AssignmentTypes
  }): Observable<BaseResponse<StudentListDropDownModel[], string>> {
    return this.http
      .get(
        StudentUrls.getAvailableStudentList(this.baseUrl)
          .replace(':groupId', request.groupId?.toString())
          .replace(':search', request.search ?? '')
          .replace(':type', request.type ?? '')
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<StudentListDropDownModel[], string> = res;
          return data;
        })
      );
  }

  public getAllGroupStudentDocument(model: string): Observable<Blob> {
    return this.httpClient
      .get(
        StudentUrls.getAllGroupStudentDocument(this.baseUrl).replace(
          ':groupId',
          model
        ),
        {
          headers: {
            Authorization: this.http.getCurrentToken(),
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          },
          responseType: 'blob'
        }
      )
      .pipe(
        map((res) => {
          const data: Blob = res;
          return data;
        })
      );
  }

  public getStudentQrCodeDocument(model: string): Observable<Blob> {
    return this.httpClient
      .get(
        StudentUrls.getStudentQrCodeDocument(this.baseUrl).replace(
          ':studentId',
          model
        ),
        {
          headers: {
            Authorization: this.http.getCurrentToken(),
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
          },
          responseType: 'blob'
        }
      )
      .pipe(
        map((res) => {
          const data: Blob = res;
          return data;
        })
      );
  }

  public getAllStudentPdfDocument(request: string, showDemoStudent: boolean): Observable<Blob> {
    return this.httpClient
      .get(StudentUrls.getAllStudentPdfDocument(this.baseUrl).replace(':search', request).replace(':showDemoStudent', showDemoStudent ? 'true' : 'false'), {
        headers: {
          Authorization:this.http.getCurrentToken(),
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        responseType: 'blob'
      })
      .pipe(
        map((res) => {
          const data: Blob = res;
          return data;
        })
      );
  }

  public exportStudentListDocument(showDemoStudent: boolean): Observable<Blob> {
    return this.httpClient
      .get(StudentUrls.exportStudentList(this.baseUrl).replace(':showDemoStudent', showDemoStudent ? 'true' : 'false'), {
        headers: {
          Authorization: this.http.getCurrentToken(),
          Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        },
        responseType: 'blob'
      })
      .pipe(
        map((res) => {
          const data: Blob = res;
          return data;
        })
      );
  }


  public studentLoginQuery(
    request: StudentLogin
  ): Observable<BaseResponse<LoginResponse, StudentLogin>> {
    return this.http
      .post(StudentUrls.studentLoginQuery(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<LoginResponse, StudentLogin> = res;
          return data;
        })
      );
  }

  public registerDemoStudent(
    request: DemoStudentLoginReq
  ): Observable<BaseResponse<LoginResponse, DemoStudentLoginReq>> {
    return this.http
      .get(
        StudentUrls.registerDemoStudent(this.baseUrl)
          .replace(':Code', request.code)
          .replace(':StudentCode', request.studentCode)
      )
      .pipe(
        map((res) => {
          const data: BaseResponse<LoginResponse, DemoStudentLoginReq> = res;
          data.request = request;
          return data;
        })
      );
  }

  public importStudents(file: FormData): Observable<Blob> {
    return this.httpClient.post(StudentUrls.importStudents(this.baseUrl), file, {
      headers: {
        Authorization: this.http.getCurrentToken(),
        Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      },
      observe: 'response', responseType: 'blob'
    }).pipe(
      map((res: any) => {
        return res;
      })
    );
  }

  public getTodayStudentTests(): Observable<BaseResponse<TodayTests, string>> {
    return this.http.get(StudentUrls.getAllStudentTodayTest(this.baseUrl)).pipe(
      map((res: BaseResponse<TodayTests, string>) => {
        if (!res.hasError && res.data.upcomingTests?.length) {
          res.data.upcomingTests = res.data.upcomingTests?.map(s => {
            return {
              ...s,
              upcomingDateLocal: moment(s.upcomingDate).utc(true).toDate()
            };
          });
        }
        return res;
      })
    );
  }

  public getSuperStudentTestDayByTestIdsQuery(
    testId: number
  ): Observable<BaseResponse<IStudentTest[], number>> {
    return this.http
      .get(
        StudentUrls.getSuperStudentTestDayByTestIdsQuery(this.baseUrl).replace(
          ':testId',
          testId?.toString()
        )
      )
      .pipe(
        map((res: BaseResponse<IStudentTest[], number>) => {
          res.request = testId;
          return res;
        })
      );
  }

  public getStudentNamesByGroupId(
    request: number
  ): Observable<BaseResponse<{ code: string; name: string }[], number>> {
    return this.http
      .get(
        StudentUrls.getStudentNameFromGroupId(this.baseUrl).replace(
          ':groupId',
          request?.toString()
        )
      )
      .pipe(
        map((res: BaseResponse<{ code: string; name: string }[], number>) => {
          res.request = request;
          return res;
        })
      );
  }

  public getStudentNamesByTestId(
    request: number
  ): Observable<BaseResponse<{ code: string; name: string }[], number>> {
    return this.http
      .get(
        TestUrls.getStudentNameFromTestId(this.baseUrl).replace(
          ':testId',
          request?.toString()
        )
      )
      .pipe(
        map((res: BaseResponse<{ code: string; name: string }[], number>) => {
          res.request = request;
          return res;
        })
      );
  }

  public moveToGroup(request: MoveStudentToGroup): Observable<BaseResponse<string, MoveStudentToGroup>> {
    return this.http
      .post(StudentUrls.moveToGroup(this.baseUrl), request)
      .pipe(
        map((res) => {
          const data: BaseResponse<string, MoveStudentToGroup> = res;
          return data;
        })
      );
  }

  public getStudentLoginEventCount(
    request: number
  ): Observable<BaseResponse<number, number>> {
    return this.http
      .get(
        StudentUrls.getStudentLoginEventCount(this.baseUrl).replace(
          ':studentId',
          request?.toString()
        )
      )
      .pipe(
        map((res: BaseResponse<number, number>) => {
          res.request = request;
          return res;
        })
      );
  }
}
