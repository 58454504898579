import {NgModule} from '@angular/core';
import {NoInternetConnectionComponent} from './no-internet-connection-component';
import {CommonModule} from '@angular/common';
import {MatDialogModule} from '@angular/material/dialog';

@NgModule({
  declarations: [NoInternetConnectionComponent],
  imports: [CommonModule, MatDialogModule],
  exports: [NoInternetConnectionComponent],
  providers: [NoInternetConnectionComponent],
})
export class NoInternetConnectionModule {}
