import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppInstallNowComponent } from './app-install-now/app-install-now.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  imports: [
    CommonModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatIconModule,
    // RouterModule.forChild([
    //   { path: '', pathMatch: 'full', component: AppInstallNowComponent }
    // ]),
  ],
  declarations: [AppInstallNowComponent],
  providers: [AppInstallNowComponent],
  exports: [AppInstallNowComponent],
})
export class AppInstallNowModule {}
