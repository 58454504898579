import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ToastService } from './services/toast.service';

@NgModule({
  imports: [CommonModule, MatSnackBarModule],
})
export class UiToastModule {
  static forRoot(): ModuleWithProviders<UiToastModule> {
    return {
      ngModule: UiToastModule,
      providers: [ToastService],
    };
  }
}
