export class GetUserEventReportData {
teacherLogins : TeacherLogin[];
lessonCreations : LessonCreation[];
lessonSharedOnMarketPlace : LessonSharedOnMarketPlace[];
studentLogins: StudentLoginModel[];
gradingMetrics: GradingMetrics[];
}
export  class TeacherLogin{
  teacherName: string;
  teacherEmail: string;
  LoginDate: Date;
  loginTime: string;

}
export class  LessonCreation{
  teacherName: string;
  lessonName: string;
  creationDate : Date;
  creationTime: string;
}

export class LessonSharedOnMarketPlace{
  teacherName: string;
  lessonName: string;
  marketPlaceLessonName: string;
  publishDate: Date;
  publishTime: string;
}

export class StudentLoginModel{
teacherName: string;
studentCode: string;
loginDate: Date;
loginTime: string;
}
export class GradingMetrics{
  teacherName: string;
  testName: string;
  assignmentName: string;
  whenGradingPerform : Date;
  time: string;
}

export class GetUserEventReportReq{
  startDate: Date;
  endDate: Date;
  eventTypes: string[];
}
export class SendReportReq extends  GetUserEventReportReq{
  email : string;
}

export class ReportTypeModel{
  name: string;
  displayName : string;
}
