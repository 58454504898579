import { createUrl } from './base-url';

export const MarketPlaceUrls = {
  downloadFromMarketPlace: (baseUrl: string) => createUrl(baseUrl, 'MarketPlace/downloadFromMarketPlace'),
  publishTeacherAssignmentToMarketPlace: (baseUrl: string) => createUrl(baseUrl, 'MarketPlace/publishTeacherAssignmentToMarketPlace'),
  publishUnPublishMarketPlace: (baseUrl: string) => createUrl(baseUrl, 'MarketPlace/publishUnPublishMarketPlace'),
  getAllMarketPlaceAssignment: (baseUrl: string) => createUrl(baseUrl, 'MarketPlace/getAllMarketPlaceAssignment'),
  getMarketPlaceAssignmentById: (baseUrl: string) => createUrl(baseUrl, 'MarketPlace/getMarketPlaceAssignmentById?Id=:id'),
  archiveMarketPlaceAssignment: (baseUrl: string) => createUrl(baseUrl, 'MarketPlace/archiveMarketPlaceAssignment?AssignmentId=:AssignmentId'),
  updateMarketPlaceAssignment: (baseUrl: string) => createUrl(baseUrl, 'MarketPlace/updateMarketPlaceAssignment')
};
