import { createUrl } from './base-url';

export const StudentUrls = {
  createStudent: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/createStudent'),
  updateStudent: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/updateStudent'),
  getStudentById: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/getStudentById/:studentId'),
  removeStudent: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/removeStudent'),

  removeAllDemoStudent: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/deleteAllDemoStudents'),
  getStudentList: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/getStudentList?search=:search&showDemoStudent=:showDemoStudent'),
  getAvailableStudentList: (baseUrl: string) =>
    createUrl(
      baseUrl,
      'Student/getAvailableStudentList/:groupId?search=:search&type=:type'
    ),
  getStudentListForDropdown: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/getStudentListForDropdown?search=:search'),
  getStudentListForDropdownWithoutDemo: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/getStudentListForDropdownWithoutDemo?search=:search'),
  getAllGroupStudentDocument: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/getAllGroupStudentDocument/:groupId'),
  getStudentQrCodeDocument: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/getStudentQrCodeDocument/:studentId'),
  getAllStudentPdfDocument: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/getAllStudentPdfDocument?search=:search&showDemoStudent=:showDemoStudent'),
  studentLoginQuery: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/studentLoginQuery'),
  getAllStudentTodayTest: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/getAllStudentTodayTest'),
  getStudentNameFromGroupId: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/getStudentNameFromGroupId/:groupId'),
  getSuperStudentTestDayByTestIdsQuery: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/getSuperStudentTestDayByTestIdsQuery/:testId'),
  registerDemoStudent: (baseUrl: string) =>
    createUrl(
      baseUrl,
      'Student/registerDemoStudent?Code=:Code&StudentCode=:StudentCode'
    ),
  importStudents: (baseUrl: string) =>
    createUrl(
      baseUrl,
      'Student/importStudents'
    ),
  exportStudentList: (baseUrl: string) =>
    createUrl(baseUrl, 'Student/downloadStudents?showDemoStudent=:showDemoStudent'),

  moveToGroup : (baseUrl: string) =>  createUrl(baseUrl, 'Student/moveToGroup'),
  getStudentLoginEventCount : (baseUrl: string) =>  createUrl(baseUrl, 'Student/getStudentLoginEventCount/:studentId')
};
