import {createUrl} from "./base-url";

export const ChatUrls = {
  sendMessage: (baseUrl: string) => createUrl(baseUrl, 'Chat/sendMessage'),
  startNewChat: (baseUrl: string) => createUrl(baseUrl, 'Chat/startNewChat'),
  getAllChatsByUserId: (baseUrl: string) => createUrl(baseUrl, 'Chat/getAllChatsByUserId'),
  getChatMessage: (baseUrl: string) => createUrl(baseUrl, 'Chat/getChatMessage'),
  getChatDetailById: (baseUrl: string) => createUrl(baseUrl, 'Chat/getChatDetailById/:chatId'),
  markAsReadMessageByChatId: (baseUrl: string) => createUrl(baseUrl, 'Chat/markAsReadMessageByChatId/:chatId'),
  getChatAdmin: (baseUrl: string) => createUrl(baseUrl, 'Chat/getChatAdmin'),
}
